// components/CustomizationDrawer.tsx
import React, { useEffect, useRef, useState } from 'react';
import { Drawer, Tooltip } from '@mui/material';

import "./CodeDrawer.css";
import JsonIcon from '../../../icon/JsonIcon';
import { CopyBlock, dracula } from 'react-code-blocks';
import config from './uikit.config.json'
import CloseIcon from '../../../icon/CloseIcon';
import { useNavbar } from '../../../hooks/useNavbar';
import { useCustomization } from '../../../hooks/useCustomization';

interface CodeDrawerProps {
    showDrawer: boolean;
    setShowDrawer?: (show: boolean) => void;
    // setPrimaryColor: (color: string) => void;
    // setTheme?: (theme: string) => void;
    // setPlatform?: (platform: string) => void;
    // setSecondaryColor?: (color: string) => void;
    // setTextCaptionColor?: (color: string) => void;
    // setBackgroundColor?: (color: string) => void;
    // getContainer?: any | null;
}



const CodeDrawer: React.FC<CodeDrawerProps> = ({ showDrawer }) => {
    const [uiKitConfig, setUIKitConfig] = useState<typeof config>({ ...config });
    const [jsonString, setJsonString] = useState<string>()
    const { applyTheme } = useCustomization();
    const { selectedTheme } = useNavbar()

    const { updateCodeMenu, selectedCodeMenu } = useNavbar()

    useEffect(() => {

        const jsonString = JSON.stringify(uiKitConfig, null, 2);
        setJsonString(jsonString)


    }, [uiKitConfig])

    useEffect(() => {

        setUIKitConfig((prevConfig) => ({
            ...prevConfig,
            theme: {
                ...prevConfig.theme,
                light: {
                    ...prevConfig.theme.light,
                    primary_color: applyTheme.primary,
                    background_color: applyTheme.background,
                    base_color: applyTheme.base,
                    base_shade1_color: applyTheme.baseShade1,
                    base_shade4_color: applyTheme.border,


                },
                dark: {
                    ...prevConfig.theme.dark,
                    primary_color: applyTheme.primary,

                },
            },
        }));

    }, [applyTheme])

    useEffect(() => {
        if (selectedTheme === "dark") {
            setUIKitConfig((prevConfig) => ({
                ...prevConfig,
                preferred_theme: "dark",
            }));

        } else {
            setUIKitConfig((prevConfig) => ({
                ...prevConfig,
                preferred_theme: "light",
            }));

        }
    }, [selectedTheme])


    return (
        <Drawer
            open={showDrawer}
            anchor="right"
            PaperProps={{
                style: {
                    background: 'transparent',
                    width: '100%',
                    boxShadow: 'none',
                    zIndex: 1,
                    paddingLeft: 12,
                    overflow: 'hidden',
                    marginTop: 83
                    //style props for your drawer menu here
                },

            }}
            ModalProps={{
                slots: { backdrop: "div" },
                slotProps: {
                    root: { //override the fixed position + the size of backdrop
                        style: {
                            position: "absolute",
                            bottom: 100,
                            left: 'unset',
                            right: 'unset'

                        },
                    },
                },
            }}

        >
            <div className='code-drawer-modal'>
                <div className='code-drawer-container'>
                    <div className='code-drawer-title'>
                        <div className='code-drawer-title'>
                            
                            
                            <JsonIcon width={28} height={28} color='#424242' />
                            <p style={{ marginLeft: 8 }}>Edit JSON</p>
                        </div>
                        <div onClick={() => updateCodeMenu("")}>
                            <CloseIcon width={24} height={24} />
                        </div>


                    </div>
                    <div className='code-drawer-description'>
                        You can use the following JSON inside your uikit.config.json directly. Your UIKit will be updated to match your playground configuration style!
                    </div>
                    <div className="scrollable-code-block">
                        <CopyBlock
                            text={jsonString}
                            language="json"
                            showLineNumbers={true}
                            theme={dracula}
                            codeBlock
                        />
                    </div>
                </div>

            </div>



            {/* Drawer content */}
        </Drawer>
    )
};

export default CodeDrawer;
