import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import CustomizationSlice from "../redux/slices/CustomizationSlice";

export const useCustomization = () => {

    const { globalTheme, applyTheme } = useSelector((state: RootState) => state.customization);
    const { updateCustomizeTheme, resetGlobalTheme, saveTheme } = CustomizationSlice.actions
    const dispatch = useDispatch();

    const updateCustomization = (themeKey: string, valueKey: string, data: string) => {
        dispatch(updateCustomizeTheme({ themeKey, valueKey, data }))
    }

    const discardGlobalTheme = () => {
        dispatch(resetGlobalTheme())
    }

    const handleApplyTheme = (data: Record<string, any>) => {
        dispatch(saveTheme(data))
    }
    return {
        globalTheme,
        applyTheme,
        updateCustomization,
        discardGlobalTheme,
        handleApplyTheme
    }

}