import React from "react";

function PageIcon({ width = 24, height = 24, color='#424242', ...props }) {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" {...props}>
            <path d="M18.2227 6.69531C18.5391 7.01172 18.75 7.46875 18.75 7.89062V19.5625C18.75 20.5117 17.9766 21.25 17.0625 21.25H6.9375C5.98828 21.25 5.25 20.5117 5.25 19.5625V4.9375C5.25 4.02344 5.98828 3.25 6.9375 3.25H14.1094C14.5312 3.25 14.9883 3.42578 15.3047 3.74219L18.2227 6.69531ZM16.9219 7.75L14.25 5.07812V7.75H16.9219ZM6.9375 19.5625H17.0625V9.4375H13.4062C12.9141 9.4375 12.5625 9.08594 12.5625 8.59375V4.9375H6.9375V19.5625Z" fill={color} />
        </svg>


    );
}

export default PageIcon;
