import React from "react";

function JsonIcon({ width = 21, height = 21, color = "#757575", ...props }) {
    return (
        <svg width={width} height={height} viewBox="0 0 21 21" fill="none" {...props} >
            <path d="M8.16406 3.54102C8.57031 3.54102 8.91406 3.88477 8.91406 4.29102C8.91406 4.72852 8.57031 5.04102 8.16406 5.04102H5.66406C5.50781 5.04102 5.41406 5.16602 5.41406 5.29102V7.72852C5.41406 8.44727 5.10156 9.16602 4.60156 9.66602L3.69531 10.541L4.60156 11.4473C5.10156 11.9473 5.41406 12.666 5.41406 13.3848V15.791C5.41406 15.9473 5.50781 16.041 5.66406 16.041H8.16406C8.57031 16.041 8.91406 16.3848 8.91406 16.791C8.91406 17.2285 8.57031 17.541 8.16406 17.541H5.66406C4.69531 17.541 3.91406 16.7598 3.91406 15.791V13.3848C3.91406 13.041 3.75781 12.7285 3.53906 12.5098L2.13281 11.0723C1.97656 10.9473 1.91406 10.7598 1.91406 10.541C1.91406 10.3535 1.97656 10.166 2.13281 10.0098L3.53906 8.60352C3.75781 8.38477 3.91406 8.07227 3.91406 7.72852V5.29102C3.91406 4.35352 4.69531 3.54102 5.66406 3.54102H8.16406ZM19.6953 10.0098C19.8203 10.166 19.9141 10.3535 19.9141 10.541C19.9141 10.7598 19.8203 10.9473 19.6953 11.0723L18.2578 12.5098C18.0391 12.7285 17.9141 13.041 17.9141 13.3848V15.791C17.9141 16.7598 17.1016 17.541 16.1641 17.541H13.6328C13.2266 17.541 12.9141 17.166 12.9141 16.791C12.9141 16.3848 13.2266 16.041 13.6641 16.041H16.1641C16.2891 16.041 16.4141 15.9473 16.4141 15.791V13.3848C16.4141 12.666 16.6953 11.9473 17.1641 11.4473L18.0703 10.541L17.1953 9.66602C16.6953 9.16602 16.4141 8.44727 16.4141 7.72852V5.29102C16.4141 5.16602 16.2891 5.04102 16.1641 5.04102H13.6328C13.2266 5.04102 12.9141 4.66602 12.9141 4.29102C12.9141 3.88477 13.2266 3.54102 13.6641 3.54102H16.1641C17.1016 3.54102 17.9141 4.35352 17.9141 5.29102V7.72852C17.9141 8.07227 18.0391 8.38477 18.2578 8.60352L19.6953 10.0098Z" fill={color} />
        </svg>


    );
}

export default JsonIcon;
