// Social

export const iOSSocialCodeSnippet = `
// AppDelegate.swift

import AmityUIKit
import AmitySDK // in order to use AmityRegionalEndpoint enum, importing AmitySDK is required.

class AppDelegate: UIResponder, UIApplicationDelegate {

    func application(_ application: UIApplication, didFinishLaunchingWithOptions launchOptions: [UIApplication.LaunchOptionsKey: Any]?) -> Bool {

        let sessionHandler = MySessionHandler()
        // Override point for customization after application launch.
        AmityUIKitManager.setup(apiKey: "YOUR_API_KEY", region: .global)

        // partially overriding colors
        let yourTheme = AmityTheme(
                        primary: 'THEME_PRIMARY_COLOR',
                      )
        AmityUIKitManager.set(theme: myTheme)
        return true
    }

}

class MySessionHandler: SessionHandler {
    func sessionWillRenewAccessToken(renewal: AccessTokenRenewal) {
        renewal.renew()
    }
}

// ViewController.swift

override func viewDidAppear() {
    super.viewDidAppear()

    AmityUIKitManager.registerDevice(withUserId: "USER_ID", displayName: "Ali Connors") // displayName is optional
    
    let viewController = AmityCommunityHomePageViewController.make()

    // push
    navigationController?.pushViewController(viewController, animated: true)

    // present
    let navigationController = UINavigationController(rootViewController: viewController)
    present(navigationController, animated: true, completion: nil)
}`;

export const androidSocialCodeSnippet = `
// MainActivity.kt

package com.amity.amityuikit

import androidx.appcompat.app.AppCompatActivity
import android.os.Bundle
import androidx.fragment.app.Fragment
import com.amity.socialcloud.sdk.AmityCoreClient
import com.amity.socialcloud.sdk.AmityRegionalEndpoint
import com.amity.socialcloud.uikit.community.home.fragments
.AmityCommunityHomePageFragment

class MainActivity : AppCompatActivity() {

    override fun onCreate(savedInstanceState: Bundle?) {
        super.onCreate(savedInstanceState)
        setContentView(R.layout.activity_main)
        if(savedInstanceState == null) {
            AmityCoreClient.setup(
                apiKey = "b3babb0b3a89f4341d31dc1a01091edcd70f8de7b23d697f",
                httpEndpoint = AmityRegionalEndpoint.SG,  // optional param, defaulted as SG region
                socketEndpoint = AmityRegionalEndpoint.SG // optional param, defaulted as SG region
            ).doOnComplete {
                AmityCoreClient.login(userId = "USER_ID")
                    .displayName(displayName = "Ali Connors") // optional
                    .build()
                    .submit()
                    .doOnComplete {
                        val fragmentManager = supportFragmentManager
                        val fragmentTransaction = fragmentManager.beginTransaction()
                        val fragment = createCommunityHomeFragment()
                        fragmentTransaction.replace(R.id.uikit, fragment)
                        fragmentTransaction.commit()
                    }
                    .subscribe()
            }.subscribe()
        }
    }

    private fun createCommunityHomeFragment(): Fragment {
        return AmityCommunityHomePageFragment.newInstance().build()
    }

}

// colors.xml
<resources>
    <color name="amityColorPrimary">THEME_PRIMARY_COLOR</color>
</resources>

// styles.xml
<style name="Amity.Base.Theme.AmityApp" parent="Amity.Base.Theme.MaterialThemeBuilder">
    <item name="colorPrimary">@color/amityColorPrimary</item>
</style>
`;

export const webSocialCodeSnippet = `
// SocialUIKit.tsx

import React from "react";
import { AmityUiKitSocial, AmityUiKitProvider } from "@amityco/ui-kit";

const theme = {
    palette: {
        primary: 'THEME_PRIMARY_COLOR',
    }
}

const SocialUIKit = () => {

  return (
     <UiKitProvider
      apiKey="YOUR_API_KEY"
      apiEndpoint="https://api.sg.amity.co" // can be https://api.sg.amity.co, https://api.eu.amity.co or https://api.us.amity.co depends on your Amity application's server region
      userId={USER_ID}
      displayName={"Ali Connors" || USER_ID} // displayName is optional
      theme={theme}
     >
        <AmityUiKitSocial />
    </AmityUiKitProvider>
  );
};

export default SocialUIKit;

// App.tsx

import React from "react";
import SocialUIKit from "./SocialUIKit"; // Adjust the path based on your project structure.

const App = () => {
  return (
    <div className="App">
      <SocialUIKit />
    </div>
  );
};

export default App;
`;

export const reactNativeSocialCodeSnippet = `
// App.tsx

import * as React from 'react';

import {
  AmityUiKitSocial,
  AmityUiKitProvider,
} from '@amityco/asc-react-native-ui-kit';

export default function App() {
  const myTheme = {
    primary: 'THEME_PRIMARY_COLOR', // Primary color for main elements
  };
  return (
    <AmityUiKitProvider
      apiKey="YOUR_API_KEY"
      apiRegion="sg" // 3 possible values "sg","eu" or "us"
      userId="USER_ID"
      displayName="Ali Connors" // displayName is optional
      theme={myTheme}
    >
      <AmityUiKitSocial />
    </AmityUiKitProvider>
  );
}
`;

export const flutterSocialCodeSnippet = `Flutter UIKit coming soon!`;

// Chat

export const iOSChatCodeSnippet = `
// AppDelegate.swift

import AmityUIKit
import AmitySDK // in order to use AmityRegionalEndpoint enum, importing AmitySDK is required.

class AppDelegate: UIResponder, UIApplicationDelegate {

    func application(_ application: UIApplication, didFinishLaunchingWithOptions launchOptions: [UIApplication.LaunchOptionsKey: Any]?) -> Bool {

        let sessionHandler = MySessionHandler()
        // Override point for customization after application launch.
        AmityUIKitManager.setup(apiKey: "YOUR_API_KEY", region: .global)

        // partially overriding colors
        let yourTheme = AmityTheme(
                        primary: 'THEME_PRIMARY_COLOR',
                      )
        AmityUIKitManager.set(theme: myTheme)
        return true
    }

}

class MySessionHandler: SessionHandler {
    func sessionWillRenewAccessToken(renewal: AccessTokenRenewal) {
        renewal.renew()
    }
}

// ViewController.swift

override func viewDidAppear() {
    super.viewDidAppear()

    AmityUIKitManager.registerDevice(withUserId: "USER_ID", displayName: "Ali Connors") // displayName is optional
    
    let viewController = AmityChatHomePageViewController.make()

    // push
    navigationController?.pushViewController(viewController, animated: true)

    // present
    let navigationController = UINavigationController(rootViewController: viewController)
    present(navigationController, animated: true, completion: nil)
}`;

export const androidChatCodeSnippet = `
// MainActivity.kt

package com.amity.amityuikit

import androidx.appcompat.app.AppCompatActivity
import android.os.Bundle
import androidx.fragment.app.Fragment
import com.amity.socialcloud.sdk.AmityCoreClient
import com.amity.socialcloud.sdk.AmityRegionalEndpoint

class MainActivity : AppCompatActivity() {

    override fun onCreate(savedInstanceState: Bundle?) {
        super.onCreate(savedInstanceState)
        setContentView(R.layout.activity_main)
        if(savedInstanceState == null) {
            AmityCoreClient.setup(
                apiKey = "b3babb0b3a89f4341d31dc1a01091edcd70f8de7b23d697f",
                httpEndpoint = AmityRegionalEndpoint.SG,  // optional param, defaulted as SG region
                socketEndpoint = AmityRegionalEndpoint.SG // optional param, defaulted as SG region
            ).doOnComplete {
                AmityCoreClient.login(userId = "USER_ID")
                    .displayName(displayName = "Ali Connors") // optional
                    .build()
                    .submit()
                    .doOnComplete {
                        val chatHomeFragment = AmityChatHomePageFragment.Builder()
                          /**
                            * set the listener to override recentItem click event
                            * No Need to implement [IRecentChatClickListener] if you don't want to override click event
                          */
                          .recentChatItemClickListener(this)
                          .build(this)

                        val transaction = supportFragmentManager.beginTransaction()
                        transaction.replace(R.id.fragmentContainer, chatHomeFragment)
                        transaction.addToBackStack(null)
                        transaction.commit()
                    }
                    .subscribe()
            }.subscribe()
        }
    }

    private fun createCommunityHomeFragment(): Fragment {
        return AmityCommunityHomePageFragment.newInstance().build()
    }

}

// colors.xml
<resources>
    <color name="amityColorPrimary">THEME_PRIMARY_COLOR</color>
</resources>

// styles.xml
<style name="Amity.Base.Theme.AmityApp" parent="Amity.Base.Theme.MaterialThemeBuilder">
    <item name="colorPrimary">@color/amityColorPrimary</item>
</style>
`;

export const webChatCodeSnippet = `
// SocialUIKit.tsx

import React from "react";
import { AmityUiKitChat, AmityUiKitProvider } from "@amityco/ui-kit";

const theme = {
    palette: {
        primary: 'THEME_PRIMARY_COLOR',
    }
}

const ChatUIKit = () => {

  return (
     <UiKitProvider
      apiKey="YOUR_API_KEY"
      apiEndpoint="https://api.sg.amity.co" // can be https://api.sg.amity.co, https://api.eu.amity.co or https://api.us.amity.co depends on your Amity application's server region
      userId={USER_ID}
      displayName={"Ali Connors" || USER_ID} // displayName is optional
      theme={theme}
     >
        <AmityUiKitChat />
    </AmityUiKitProvider>
  );
};

export default ChatUIKit;

// App.tsx

import React from "react";
import ChatUIKit from "./ChatUIKit"; // Adjust the path based on your project structure.

const App = () => {
  return (
    <div className="App">
      <ChatUIKit />
    </div>
  );
};

export default App;
`

export const reactNativeChatCodeSnippet = `
// App.tsx

import * as React from 'react';

import {
  AmityUiKitChat,
  AmityUiKitProvider,
} from '@amityco/asc-react-native-ui-kit';

export default function App() {
  const myTheme = {
    primary: 'THEME_PRIMARY_COLOR', // Primary color for main elements
  };
  return (
    <AmityUiKitProvider
      apiKey="YOUR_API_KEY"
      apiRegion="sg" // 3 possible values "sg","eu" or "us"
      userId="USER_ID"
      displayName="Ali Connors" // displayName is optional
      theme={myTheme}
    >
      <AmityUiKitChat />
    </AmityUiKitProvider>
  );
}
`

export const flutterChatCodeSnippet = `Flutter UIKit coming soon!`;
