import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ILayerItemprops } from '../../components/LayerItem/LayerItem';



interface RecentChatState {
  globalLayerItems: NavigationItem[];
  socialLayerItems: NavigationItem[];
  chatLayerItems: NavigationItem[];
  selectedItem: NavigationItem;
  expandedItems: string[];
}
export type NavigationItem = {
  uiReference: string
  parentUIReference: string
  name: string
}
const initialState: RecentChatState = {
  globalLayerItems: [
    {
      uiReference: "*/*/*",
      parentUIReference: "none",
      name: "App"
    },
  ],
  socialLayerItems: [


    {
      uiReference: "newsfeed_page/*/*",
      parentUIReference: "*/*/*",
      name: "News Feed"
    },
    // -- component
    // {
    //   uiReference: "newsfeed_page/story_feed/*",
    //   parentUIReference: "newsfeed_page/*/*",
    //   name: "Story Feed"
    // },
    // {
    //   uiReference: "newsfeed_page/tabs/*",
    //   parentUIReference: "newsfeed_page/*/*",
    //   name: "Tabs"
    // },
    // // ---- element
    // {
    //   uiReference: "newsfeed_page/story_feed/story_ring",
    //   parentUIReference: "newsfeed_page/story_feed/*",
    //   name: "Ring Border"
    // },
    // {
    //   uiReference: "newsfeed_page/story_feed/story_feed_title",
    //   parentUIReference: "newsfeed_page/story_feed/*",
    //   name: "Story title"
    // },
    // {
    //   uiReference: "newsfeed_page/story_feed/community_badge",
    //   parentUIReference: "newsfeed_page/story_feed/*",
    //   name: "Community badge"
    // },
    {
      uiReference: "explore_page/*/*",
      parentUIReference: "*/*/*",
      name: "Explore"
    },
    {
      uiReference: "my_community/*/*",
      parentUIReference: "*/*/*",
      name: "My community"
    },
    {
      uiReference: "community_profile/*/*",
      parentUIReference: "*/*/*",
      name: "Community profile"
    },
    {
      uiReference: "user_profile/*/*",
      parentUIReference: "*/*/*",
      name: "User profile"
    },
  ],
  chatLayerItems: [
    {
      uiReference: "channel_list/*/*",
      parentUIReference: "*/*/*",
      name: "Channel list"
    },
    {
      uiReference: "chat_room/*/*",
      parentUIReference: "*/*/*",
      name: "Chat room"
    },
    {
      uiReference: "chat_setting/*/*",
      parentUIReference: "*/*/*",
      name: "Chat setting"
    },
  ],
  selectedItem: {
    uiReference: "*/*/*",
    parentUIReference: "none",
    name: "App"
  },
  expandedItems: []
};

const layerDrawerSlice = createSlice({
  name: 'layerDrawer',
  initialState,
  reducers: {


    updateSelectedItem: (
      state,
      action: PayloadAction<NavigationItem>
    ) => {
      const item = action.payload;
      state.selectedItem = item;
    },
    openLayerItem: (
      state,
      action: PayloadAction<NavigationItem>
    ) => {
      const item = action.payload;
      if (!state.expandedItems.includes(item.uiReference)) {
        const current = [...state.expandedItems]
        current.push(item.uiReference)
        state.expandedItems = current;
      }




    },
    closeLayerItem: (
      state,
      action: PayloadAction<NavigationItem>
    ) => {
      const removedItem = action.payload;
      const current = [...state.expandedItems]

      const result = current.filter(item => item !== removedItem.uiReference);
      state.expandedItems = result
    },

  },
});

export default layerDrawerSlice;
