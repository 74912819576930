import React from "react";

function LayerIcon({ width = 19, height = 19, ...props }) {
    return (
        <svg width={width} height={height} viewBox="0 0 19 19" {...props}>
            <path d="M1.02539 5.98438C0.427734 5.70312 0.427734 4.82422 1.02539 4.54297L9.2168 0.851562C9.32227 0.816406 9.46289 0.746094 9.60352 0.746094C9.70898 0.746094 9.84961 0.816406 9.95508 0.851562L18.1465 4.54297C18.7441 4.82422 18.7441 5.70312 18.1465 5.98438L9.95508 9.67578C9.70898 9.78125 9.46289 9.78125 9.2168 9.67578L1.02539 5.98438ZM18.1465 9.07812C18.7441 9.32422 18.7441 10.2031 18.1465 10.4844L9.95508 14.1758C9.70898 14.2812 9.46289 14.2812 9.2168 14.1758L1.02539 10.4844C0.427734 10.2031 0.427734 9.32422 1.02539 9.07812L3.06445 8.12891L8.75977 10.7305C9.00586 10.8359 9.28711 10.9062 9.60352 10.9062C9.88477 10.9062 10.166 10.8359 10.4121 10.7305L16.1074 8.12891L18.1465 9.07812ZM18.1465 13.5781C18.7441 13.8242 18.7441 14.7031 18.1465 14.9844L9.95508 18.6758C9.70898 18.7812 9.46289 18.7812 9.2168 18.6758L1.02539 14.9844C0.427734 14.7031 0.427734 13.8242 1.02539 13.5781L3.06445 12.6289L8.75977 15.2305C9.00586 15.3359 9.28711 15.4062 9.60352 15.4062C9.88477 15.4062 10.166 15.3359 10.4121 15.2305L16.1074 12.6289L18.1465 13.5781Z" fill="#424242" />
        </svg>

    );
}

export default LayerIcon;
