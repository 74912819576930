import React, { useEffect, useState } from "react";
import { Form, Input, Button, Select, Radio } from "antd";
import "./PublicForm.css";
import socialModule from "./social-module.png";
import chatModule from "./chat-module.png";
import { FaRocket } from "react-icons/fa6";

const { Option } = Select;

type IPublicForm = {
  onFinishForm: (values: Record<string, any>) => void;
  category?: string;
};

const PublicForm: React.FC<IPublicForm> = ({
  onFinishForm,
  category,
}: IPublicForm) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const [module, setModule] = useState("social");

  useEffect(() => {
    form.setFieldsValue({ module: "social" });
  }, []);

  useEffect(() => {
    if (category) {
      form.setFieldsValue({ category: category });
    }
  }, [category]);

  const onFinish = (values) => {
    setLoading(true);
    onFinishForm && onFinishForm(values);
    localStorage.setItem("category", values.category);
    localStorage.setItem("email", values.email);
    localStorage.setItem("displayName", values.displayName);
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    const atIndex = email.indexOf("@");
    if (atIndex !== -1) {
      const displayName = email.substring(0, atIndex);
      form.setFieldsValue({ displayName });
    }
  };

  const selectModule = (name: string) => {
    form.setFieldsValue({ module: name });
    setModule(name);
  };

  return (
    <div>
      <div>
        <p className="form-title">Hello! Welcome to Amity Playground 🎨 🪄</p>
        <p className="form-subtitle">
          Fill information below to start your journey!
        </p>
      </div>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item name="email" label="Email">
          <Input onChange={handleEmailChange} />
        </Form.Item>

        <Form.Item name="displayName" label="Display Name">
          <Input />
        </Form.Item>
        {!category && (
          <>
            <Form.Item name="category" label="Category">
              <Select placeholder="Select a category">
                <Option value="gaming">Gaming</Option>
                <Option value="sport">Sport</Option>
                <Option value="financial">Financial</Option>
                <Option value="travel">Travel</Option>
                <Option value="fitness">Fitness</Option>
                <Option value="automotive">Automotive</Option>
              </Select>
            </Form.Item>
            <Form.Item name="module" label="Select your module 🤔">
              <div className="select-module-row">
                <div
                  onClick={() => selectModule("social")}
                  className="module-item-box"
                >
                  <Radio
                    value={"social"}
                    checked={module === "social"}
                    className="module-box-text"
                  >
                    {" "}
                    Social 📱
                  </Radio>
                  <img width={"100%"} src={socialModule} />
                </div>
                <div
                  onClick={() => selectModule("chat")}
                  className="module-item-box"
                >
                  <Radio
                    value={"chat"}
                    checked={module === "chat"}
                    className="module-box-text"
                  >
                    Chat 💬
                  </Radio>
                  <img width={"100%"} src={chatModule} />
                </div>
              </div>
            </Form.Item>
          </>
        )}

        <Form.Item>
          <Button
            className="start-btn"
            icon={<FaRocket />}
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            Let's start
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default PublicForm;
