import React from "react";

function ArrowRightIcon({ width = 17, height = 17, color = '#424242', ...props }) {
    return (
        <svg width={width} height={height} {...props} viewBox="0 0 17 17" >
            <path d="M10.9414 8.99512L6.39453 13.5654C6.16016 13.7764 5.80859 13.7764 5.59766 13.5654L5.05859 13.0264C4.84766 12.8154 4.84766 12.4639 5.05859 12.2295L8.66797 8.59668L5.05859 4.9873C4.84766 4.75293 4.84766 4.40137 5.05859 4.19043L5.59766 3.65137C5.80859 3.44043 6.16016 3.44043 6.39453 3.65137L10.9414 8.22168C11.1523 8.43262 11.1523 8.78418 10.9414 8.99512Z" fill={color} />
        </svg>
    );
}

export default ArrowRightIcon;
