import React from "react";

function ComponentIcon({ width = 17, height = 16, color = '#757575', ...props }) {
    return (
        <svg width={width} height={height} viewBox="0 0 17 16" {...props}>
        <path d="M0.938477 1.71094C0.938477 0.796875 1.67676 0.0234375 2.62598 0.0234375H6.00098C6.91504 0.0234375 7.68848 0.796875 7.68848 1.71094V5.08594C7.68848 6.03516 6.91504 6.77344 6.00098 6.77344H2.62598C1.67676 6.77344 0.938477 6.03516 0.938477 5.08594V1.71094ZM2.62598 5.08594H6.00098V1.71094H2.62598V5.08594ZM0.938477 10.7109C0.938477 9.79688 1.67676 9.02344 2.62598 9.02344H6.00098C6.91504 9.02344 7.68848 9.79688 7.68848 10.7109V14.0859C7.68848 15.0352 6.91504 15.7734 6.00098 15.7734H2.62598C1.67676 15.7734 0.938477 15.0352 0.938477 14.0859V10.7109ZM2.62598 14.0859H6.00098V10.7109H2.62598V14.0859ZM15.001 0.0234375C15.915 0.0234375 16.6885 0.796875 16.6885 1.71094V5.08594C16.6885 6.03516 15.915 6.77344 15.001 6.77344H11.626C10.6768 6.77344 9.93848 6.03516 9.93848 5.08594V1.71094C9.93848 0.796875 10.6768 0.0234375 11.626 0.0234375H15.001ZM15.001 1.71094H11.626V5.08594H15.001V1.71094ZM9.93848 10.7109C9.93848 9.79688 10.6768 9.02344 11.626 9.02344H15.001C15.915 9.02344 16.6885 9.79688 16.6885 10.7109V14.0859C16.6885 15.0352 15.915 15.7734 15.001 15.7734H11.626C10.6768 15.7734 9.93848 15.0352 9.93848 14.0859V10.7109ZM11.626 14.0859H15.001V10.7109H11.626V14.0859Z" fill={color}/>
        </svg>
        
    );
}

export default ComponentIcon;
