import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ILayerItemprops } from '../../components/LayerItem/LayerItem';



interface NavbarState {
  selectedTheme: 'dark' | 'light';
  selectedContent: string;
  selectedCodeMenu: string;
  selectedPlatform: string;
}

const initialState: NavbarState = {
  selectedTheme: 'light',
  selectedContent: 'travel',
  selectedCodeMenu: '',
  selectedPlatform: 'mobile'
};

const navbarSlice = createSlice({
  name: 'navbar',
  initialState,
  reducers: {


    updateSelectedTheme: (
      state,
      action: PayloadAction<'dark' | 'light'>
    ) => {
      const item = action.payload;
      state.selectedTheme = item;
    },
    updateSelectedContent: (
      state,
      action: PayloadAction<string>
    ) => {
      const item = action.payload;
      state.selectedContent = item;
    },
    updateSelectedCodeMenu: (
      state,
      action: PayloadAction<string>
    ) => {
      const item = action.payload;
      state.selectedCodeMenu = item;
    },
    updateSelectedPlatform: (
      state,
      action: PayloadAction<string>
    ) => {
      const item = action.payload;
      state.selectedPlatform = item;
    },

  },
});

export default navbarSlice;
