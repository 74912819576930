import * as React from 'react';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab, { tabClasses } from '@mui/joy/Tab';
import MobileIcon from '../../icon/MobileIcon';
import DesktopIcon from '../../icon/DesktopIcon';
import { makeStyles } from '@material-ui/core/styles';
import { Number } from 'aws-sdk/clients/iot';



const useStyles = makeStyles(theme => ({
    tab: {
        position: 'relative', // Ensure proper positioning for the text
        right: 1,

    },
    indicator: {
        position: 'absolute',
        height: 40,
        width: 61, // Set to half the width of the tabs
        borderRadius: '8px',
        backgroundColor: '#FFFFFF',
        transition: 'transform 0.3s cubic-bezier(0.55, 0, 0.1, 1.1)', // Adjusted cubic bezier for less bounce
        zIndex: -1, // Move the indicator behind the text
        boxShadow: '0px 0px 1px 0px rgba(40, 41, 61, 0.08), 0px 0.5px 2px 0px rgba(96, 97, 112, 0.16)' // Box shadow
    },
}));

interface ISegmentTab {
    leftIcon: React.JSX.Element;
    rightIcon: React.JSX.Element;
    onSwitchTab?: (value: number) => void;
}


export default function SegmentedTabs({ leftIcon, rightIcon, onSwitchTab }: ISegmentTab) {
    const classes = useStyles();
    const [tabValue, setTabValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
        onSwitchTab && onSwitchTab(newValue)
    };

    return (
        <Tabs
            value={tabValue}
            onChange={handleChange}
            aria-label="tabs"
            defaultValue={0}
            sx={{ width: 130, bgcolor: 'transparent' }}>
            <TabList
                disableUnderline
                sx={{
                    p: 0.4,
                    gap: 0.4,
                    borderRadius: 12,
                    bgcolor: '#F5F5F5', // Set background color to transparent
                    border: '1px solid #FAFAFA',
                    [`& .${tabClasses.root}[aria-selected="true"]`]: {
                        bgcolor: 'transparent', // Set background color to transparent for the selected tab

                    },

                    '& .MuiTab-root': {
                        bgcolor: 'transparent',
                        fontWeight: 600,
                        fontSize: 16,
                        borderRadius: 12,
                    },
                    '& .MuiTab-variantPlain:focus': {
                        bgcolor: 'transparent'

                    },
                    '& .MuiTab-horizontal': {
                        bgcolor: 'transparent',
                        height: 48,
                        width: 300,


                    },
                    '& .MuiTab-variantPlain': {
                        bgcolor: 'transparent'

                    },



                }}>
           
                    <>
                    <Tab
                        disableIndicator
                        className={classes.tab}
                        style={{ opacity: tabValue === 0 ? 1 : 0.4, width: 65, height: 40 }}>
                        {/* <MobileIcon height={30} width={30} /> */}
                        {leftIcon}
                    </Tab>

                    <Tab
                        className={classes.tab}
                        style={{ opacity: tabValue === 1 ? 1 : 0.4, width: 65, height: 40 }}

                        disableIndicator>

                        {rightIcon}

                        {/* <DesktopIcon height={30} width={32} /> */}

                    </Tab>
                    </>
           
                {/* Indicator for bouncy effect */}
                <div
                    className={classes.indicator}
                    style={{
                        transform: tabValue === 0 ? 'translateX(0)' : 'translateX(100%)',
                    }}
                />
            </TabList>
        </Tabs >
    );
}
