// pages/Home.js
import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import "./ChatUIKit.css";
import CustomButton from "../../components/CustomButton/CustomButton";
import { FaWandMagicSparkles } from "react-icons/fa6";
import { useLocation } from "react-router-dom";
import InspectCodeDrawer from "../../components/Drawer/InspectCodeDrawer/InspectCodeDrawer";
import { ReactComponent as LeftChevronIcon } from "./left-chevron.svg";
import {
  createChatContent,
  getGeneratingStatus,
} from "../../utils/apiServices";
import { Progress } from "antd";
declare global {
  interface Window {
    Appcues: any;
  }
}
// const apiKey = process.env.REACT_APP_ASC_API_KEY;
// const ascRegion = process.env.REACT_APP_ASC_REGION
const UIKITURL = process.env.REACT_APP_UIKIT_URL;
const ChatUIKit = ({ userId, apiKey, region, networkId }) => {
  const [loading, setLoading] = useState(false);
  const [theme, setTheme] = useState("light");
  const [videoReady, setVideoReady] = useState(false);
  const [platform, setPlatform] = useState("mobile");
  const [primaryColor, setPrimaryColor] = useState("#1054de");
  const [secondaryColor, setSecondaryColor] = useState("#04be8c");
  const [textCaptionColor, setTextCaptionColor] = useState("#04be8c");
  const [backgroundColor, setBackgroundColor] = useState("#04be8c");
  const [showCustomization, setShowCustomization] = useState(false);
  const [generatingStatus, setGeneratingStatus] = useState("");
  const [generatingPercentage, setGeneratingPercentage] = useState(0);
  var loadingIteration = 0;
  const mockStatusList = [
    "Preparing content...",
    "Generating Chat Channels...",
    "Generating Text Messages...",
    "Generating Image Messages...",
    "Creating Category...",
    "Generating Community...",
    "Generating Text Post...",
    "Generating Image Post...",
    "Generating Poll Post...",
    "Processing Chat Channels...",
    "Processing Text Messages...",
    "Processing Image Messages...",
    "Processing category...",
    "Processing Community...",
    "Processing Text Post...",
    "Processing Image Post...",
    "Processing Poll Post...",
    "Optimizing Interface...",
    "Finalizing...",
  ];
  const handleCustomization = () => {
    setShowCustomization(!showCustomization);
  };
  const handleVideoLoad = () => {
    setVideoReady(true);
  };

  const { pathname } = useLocation();
  const [chatContainer, setChatContainer] = useState(null);
  useEffect(() => {
    setChatContainer(document.querySelector(".chat-main-container"));
  }, []);
  // useEffect(() => {
  //   if (loading && !isContentReady) {
  //     checkMockGeneratingStatus();
  //   }
  // }, [loading, isContentReady]);

  const generateContent = async (category) => {
    try {
      setLoading(true);
    } catch (e) {
      console.error(e);
    }
  };

  const checkMockGeneratingStatus = async () => {
    try {
      console.log("checking loading interation " + loadingIteration);
      for (
        loadingIteration = 1;
        loadingIteration <= mockStatusList.length;
        loadingIteration++
      ) {
        console.log("check loading iteration " + loadingIteration);

        if (loadingIteration === 1) {
          await new Promise((resolve) => setTimeout(resolve, 3000));
        }
        const status = mockStatusList[loadingIteration - 1];
        console.log("Mock status update: ", status);
        setGeneratingStatus(status);
        setGeneratingPercentage(loadingIteration * 5);
        if (loadingIteration >= mockStatusList.length) {
          loadingIteration = 1;
          break;
        }
        await new Promise((resolve) => setTimeout(resolve, 5000));
      }
    } catch (error) {
      console.error("Error in mock generating status", error);
    }
  };
  const fillUserInformation = (
    email: string,
    displayName: string,
    category: string,
    module: string
  ) => {
    console.log("module: ", module);
    console.log("category: ", category);
    console.log("displayName: ", displayName);
    console.log("email: ", email);
  };

  return (
    <Navbar
      networkId={networkId}
      generateContent={generateContent}
      setFormValues={fillUserInformation}
    >
      {(showInspectCode, setShowInspectCode) => (
        <div className="chat-main-container">
          {!loading ? (
            <div className="chat-container">
              <iframe
                src={`${UIKITURL}/chat?darkMode=${
                  theme === "dark" ? true : false
                }&primary=${(primaryColor as string).replace(
                  /#/g,
                  ""
                )}&apiKey=${apiKey}&userId=${userId}&apiRegion=${region}`}
                width="395"
                height="695"
                className="chat-iframe"
              />

              <div className="chat-customization-btn-container">
                <CustomButton
                  className="customization-btn"
                  text="Customization"
                  color="secondary"
                  icon={
                    <FaWandMagicSparkles className="customization-button-left-icon" />
                  }
                  onClick={handleCustomization}
                  alignIconRightMost={false}
                  rightIcon={
                    <LeftChevronIcon className="customization-button-right-icon" />
                  }
                />
              </div>
            </div>
          ) : (
            <div className="loading-container">
              <div
                className={`loading-modal-content ${
                  videoReady ? "" : "hidden"
                }`}
              >
                <div className="video-wrap">
                  <video
                    autoPlay
                    width="220"
                    height="220"
                    loop
                    onLoadedData={handleVideoLoad}
                  >
                    <source
                      src="https://api.sg.amity.co/api/v3/files/65b75b03265602bce94d8d6a/download"
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div style={{ width: "200px", marginTop: "16px" }}>
                  <Progress
                    strokeColor="#3B41EC"
                    percent={generatingPercentage}
                    size="small"
                    status="active"
                    showInfo={true}
                  />
                </div>
                <p
                  style={{
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: "16px",
                    letterSpacing: "-0.1px",
                  }}
                >
                  {generatingStatus || "Initializing..."}{" "}
                </p>
              </div>
            </div>
          )}

          <InspectCodeDrawer
            showInspectCode={showInspectCode}
            module="chat"
            setShowInspectCode={setShowInspectCode}
            primaryColor={primaryColor}
            getContainer={chatContainer}
            apiKey={apiKey}
          />
          {/* <CustomizationDrawer
            showCustomization={showCustomization}
            setShowCustomization={setShowCustomization}
            setTheme={setTheme}
            setPlatform={setPlatform}
            setPrimaryColor={setPrimaryColor}
            setSecondaryColor={setSecondaryColor}
            setTextCaptionColor={setTextCaptionColor}
            setBackgroundColor={setBackgroundColor}
            getContainer={chatContainer}
            // Pass other required props for CustomizationDrawer
          /> */}
        </div>
      )}
    </Navbar>
  );
};

export default ChatUIKit;
