import React from "react";

function ApplicationIcon({ width = 21, height = 20, color = '#424242', ...props }) {
    return (
        <svg  width={width} height={height} viewBox="0 0 21 20">
            <path d="M15.7842 1.25C15.2917 1.25 14.8041 1.347 14.3491 1.53545C13.8941 1.72391 13.4807 2.00013 13.1325 2.34835C12.7843 2.69657 12.5081 3.10997 12.3196 3.56494C12.1312 4.01991 12.0342 4.50754 12.0342 5C12.0342 5.49246 12.1312 5.98009 12.3196 6.43506C12.5081 6.89003 12.7843 7.30343 13.1325 7.65165C13.4807 7.99987 13.8941 8.27609 14.3491 8.46455C14.8041 8.653 15.2917 8.75 15.7842 8.75C16.2766 8.75 16.7643 8.653 17.2192 8.46455C17.6742 8.27609 18.0876 7.99987 18.4358 7.65165C18.784 7.30343 19.0603 6.89003 19.2487 6.43506C19.4372 5.98009 19.5342 5.49246 19.5342 5C19.5342 4.50754 19.4372 4.01991 19.2487 3.56494C19.0603 3.10997 18.784 2.69657 18.4358 2.34835C18.0876 2.00013 17.6742 1.72391 17.2192 1.53545C16.7643 1.347 16.2766 1.25 15.7842 1.25ZM5.78418 11.25C4.78962 11.25 3.83579 11.6451 3.13253 12.3483C2.42927 13.0516 2.03418 14.0054 2.03418 15C2.03418 15.9946 2.42927 16.9484 3.13253 17.6517C3.83579 18.3549 4.78962 18.75 5.78418 18.75C6.77874 18.75 7.73257 18.3549 8.43583 17.6517C9.13909 16.9484 9.53418 15.9946 9.53418 15C9.53418 14.0054 9.13909 13.0516 8.43583 12.3483C7.73257 11.6451 6.77874 11.25 5.78418 11.25Z" fill={color} />
            <path d="M5.78418 1.25C5.29172 1.25 4.80409 1.347 4.34912 1.53545C3.89415 1.72391 3.48075 2.00013 3.13253 2.34835C2.78431 2.69657 2.50809 3.10997 2.31963 3.56494C2.13118 4.01991 2.03418 4.50754 2.03418 5C2.03418 5.49246 2.13118 5.98009 2.31963 6.43506C2.50809 6.89003 2.78431 7.30343 3.13253 7.65165C3.48075 7.99987 3.89415 8.27609 4.34912 8.46455C4.80409 8.653 5.29172 8.75 5.78418 8.75C6.27664 8.75 6.76427 8.653 7.21924 8.46455C7.67421 8.27609 8.08761 7.99987 8.43583 7.65165C8.78405 7.30343 9.06027 6.89003 9.24873 6.43506C9.43718 5.98009 9.53418 5.49246 9.53418 5C9.53418 4.50754 9.43718 4.01991 9.24873 3.56494C9.06027 3.10997 8.78405 2.69657 8.43583 2.34835C8.08761 2.00013 7.67421 1.72391 7.21924 1.53545C6.76427 1.347 6.27664 1.25 5.78418 1.25ZM15.7842 11.25C14.7896 11.25 13.8358 11.6451 13.1325 12.3483C12.4293 13.0516 12.0342 14.0054 12.0342 15C12.0342 15.9946 12.4293 16.9484 13.1325 17.6517C13.8358 18.3549 14.7896 18.75 15.7842 18.75C16.7787 18.75 17.7326 18.3549 18.4358 17.6517C19.1391 16.9484 19.5342 15.9946 19.5342 15C19.5342 14.0054 19.1391 13.0516 18.4358 12.3483C17.7326 11.6451 16.7787 11.25 15.7842 11.25Z" fill={color}  />
        </svg>
    );
}

export default ApplicationIcon;
