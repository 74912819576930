
import { ReactNode } from 'react';
import './CustomButton.css';
import { IconType } from 'react-icons';

interface ICustomButtonprops {
    text: string,
    icon?: ReactNode;
    color: 'primary' | 'secondary';
    onClick?: () => any;
    className?: string;
    rightIcon?: ReactNode;
    alignIconRightMost?: boolean;
    disabled?: boolean; // Added disabled prop
}
const CustomButton = ({ text, icon, color, onClick, className, rightIcon, alignIconRightMost, disabled }: ICustomButtonprops) => {
    const handleOnClick = () => {
        if (!disabled) {
            onClick && onClick();
        }
    }
    return (
        <button onClick={handleOnClick} className={color === 'primary' ? `primary-btn ${className}` : `secondary-btn ${className}`}>
            {rightIcon
                ? <>
                    <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {icon}
                            {text}
                            {!alignIconRightMost && rightIcon}
                        </div>
                        {alignIconRightMost && <span>{rightIcon}</span>}

                    </div>
                </>
                : <>
                    {icon}
                    {text}</>}

        </button>
    );
};

export default CustomButton;