import React, { useEffect, useRef, useState } from "react";
import "./ColorInput.css"
import ColorPicker from "react-best-gradient-color-picker";
import { useCustomization } from "../../hooks/useCustomization";

interface IColorInput {
    value: string;
    title: string;
    themeKey: string;
    valueKey: string;
    position?: string

}

const ColorInput = ({ value = '', title = '', themeKey, valueKey, position = 'bottom' }: IColorInput): JSX.Element => {


    const [selectedColor, setSelectedColor] = useState<string>(value ?? '');
    const [isPickerVisible, setIsPickerVisible] = useState(false);
    const inputRef = useRef(null);
    const { updateCustomization } = useCustomization()

    useEffect(() => {
        if (value) {
            setSelectedColor(value)
        }

    }, [value])


    const toHex = (color) => {
        if (color.startsWith('#')) {
            return color;
        }

        let ctx = document.createElement('canvas').getContext('2d');
        ctx.fillStyle = color;
        return ctx.fillStyle
    };


    const handleColorChange = (color) => {
        setSelectedColor(toHex(color));
        updateCustomization(themeKey, valueKey, toHex(color))
    };

    const togglePickerVisibility = () => {
        setIsPickerVisible(!isPickerVisible);
    };

    const handleClickOutside = (event) => {
        if (inputRef.current && !inputRef.current.contains(event.target)) {
            setIsPickerVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div>
            <p className="color-input-title">{title}</p>
            <div ref={inputRef} className='color-input-container'>
                <p className='color-input-header'>#</p>
                <p className='color-code-text'>{selectedColor.slice(1)}</p>
                <input
                    type="text"
                    className='color-input'
                    onClick={togglePickerVisibility}
                    style={{
                        backgroundColor: selectedColor,
                        width: 39,
                        height: 39,
                    }}
                    readOnly
                />
                {isPickerVisible && (
                    <div
                        style={{
                            position: 'absolute',
                            bottom: position === 'top'? '100%':'',
                            top: position === 'bottom'?'100%':'',
                            left: -10,
                            zIndex: 100,
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            borderRadius: '4px',
                            overflow: 'hidden',

                        }}
                    >
                        <div style={{ padding: 10, background: '#ffffff' }}>
                            <ColorPicker
                                width={270}
                                height={200}
                                value={selectedColor}
                                onChange={handleColorChange}
                                hideOpacity
                                hideGradientControls
                                hideControls
                                hidePresets
                            />
                        </div>
                    </div>
                )}
            </div>

        </div>
    );
};

export default ColorInput;
