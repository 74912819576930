import React from "react";

function ElementIcon({ width = 25, height = 24, color = '#757575', ...props }) {
    return (
        <svg width={width} height={height} viewBox="0 0 25 24" >
            <path d="M9.6875 4.375C10.6016 4.375 11.375 5.14844 11.375 6.0625V9.4375C11.375 10.3867 10.6016 11.125 9.6875 11.125H8.84375V14.5C8.84375 15.3086 9.44141 15.9062 10.25 15.9062H13.625V15.0625C13.625 14.1484 14.3633 13.375 15.3125 13.375H18.6875C19.6016 13.375 20.375 14.1484 20.375 15.0625V18.4375C20.375 19.3867 19.6016 20.125 18.6875 20.125H15.3125C14.3633 20.125 13.625 19.3867 13.625 18.4375V17.5938H10.25C8.52734 17.5938 7.15625 16.2227 7.15625 14.5V11.125H6.3125C5.36328 11.125 4.625 10.3867 4.625 9.4375V6.0625C4.625 5.14844 5.36328 4.375 6.3125 4.375H9.6875ZM9.6875 6.0625H6.3125V9.4375H9.6875V6.0625ZM18.6875 15.0625H15.3125V18.4375H18.6875V15.0625Z" fill={color} />
        </svg>

    );
}

export default ElementIcon;
