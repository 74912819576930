import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import NavbarSlice from "../redux/slices/NavbarSlice";

export const useNavbar = () => {

    const { selectedContent, selectedTheme, selectedCodeMenu, selectedPlatform } = useSelector((state: RootState) => state.navbar);
    const { updateSelectedTheme, updateSelectedContent, updateSelectedCodeMenu, updateSelectedPlatform } = NavbarSlice.actions
    const dispatch = useDispatch();

    const updateCurrentThemeSelect = (item: 'dark' | 'light') => {
        dispatch(updateSelectedTheme(item))
    }
    const updateContentSelect = (item: string) => {
        dispatch(updateSelectedContent(item))
    }

    const updateCodeMenu = (item: string) => {
        dispatch(updateSelectedCodeMenu(item))
    }
    const updatePlatform = (item: string) => {
        dispatch(updateSelectedPlatform(item))
    }
    return {
        selectedCodeMenu,
        selectedContent,
        selectedTheme,
        selectedPlatform,
        updateCurrentThemeSelect,
        updateContentSelect,
        updateCodeMenu,
        updatePlatform

    }

}