import { consoleCookiesCallAdminAPI } from "./apiServices";

const checkConsoleCookies = async (region?: string) => {
  const temp = [];
  try {
    const result = await consoleCookiesCallAdminAPI(region);
    console.log('result: ', result);
    if (typeof result === "object") {
      for (const network of result) {
        temp.push({
          name: network.user.username,
          networkId: network.user.networkId,
          username: network.user.username,
          region: network.region,
          email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(network.user.username)
            ? network.user.username
            : "amity@amity.co",
          adminToken: network.user.adminToken,
          apiKey: network.apiKey,
          privateUserId: network.user._id,
          userPublicId: network.user.userPublicId,
          isSecureModeEnabled: network.isSecureModeEnabled,
          isSocialEnabled: network.isSocialEnabled,
        });
      }
      return temp;
    } else {
      console.log("No cookies found");
    }
  } catch (error) {
    console.log("An error occurred:", error);
  }
};

export { checkConsoleCookies };
