import React from "react";

function DarkModeIcon({ width = 24, height = 24, ...props }) {
    return (
        <svg width={width} height={height} {...props}>
            <path d="M13.4492 21.8533C8.45703 21.8533 4.44922 17.8455 4.44922 12.8533C4.44922 7.8963 8.45703 3.85333 13.4492 3.85333C13.9062 3.88849 14.6445 3.92365 15.0664 4.02911C15.4531 4.09943 15.5586 4.59161 15.207 4.80255C12.9922 6.06818 11.6562 8.38849 11.6562 10.9197C11.6562 15.3494 15.6641 18.6893 20.0234 17.8455C20.4102 17.7752 20.6914 18.2322 20.4453 18.5486C18.7578 20.6229 16.2266 21.8533 13.4492 21.8533Z" fill="#424242" />
        </svg>

    );
}

export default DarkModeIcon;
