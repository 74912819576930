import React, { useEffect, useState } from "react";
import { Form, Input, Button, Select } from "antd";
import welcome from "./welcome.png";
import PublicForm from "../../components/PublicForm/PublicForm";

const { Option } = Select;

const UIKITURL = process.env.REACT_APP_UIKIT_URL;

interface ILoginForm {
  email: string;
  displayName: string;
  category: string;
  module: string;
}
const SocialUIKitMobile = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [isFilledForm, setIsFilledForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [theme, setTheme] = useState("light");
  const [primaryColor, setPrimaryColor] = useState("#1054de");
  const [textColor, setTextColor] = useState("#292B32");

  const [textCaptionColor, setTextCaptionColor] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("#FFFFFF");

  const [loginForm, setLoginForm] = useState<ILoginForm>();

  const [category, setCategory] = useState<string>();

  const colorCodeToString = (value: string) => {
    const newString = (value as string).replace(/#/g, "");
    return newString;
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const category = urlParams.get("category");
    const primary = urlParams.get("primary");
    if (primary) setPrimaryColor("#" + primary);
    if (category) {
      setCategory(category);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onFinish = (values: ILoginForm) => {
    setLoading(true);
    setLoginForm(values);
    setIsFilledForm(true);
    console.log("Received values:", values);
    // Here you can perform your login logic, such as sending a request to your backend
    // setLoading(false); // Set loading to false when your login operation is complete
  };

  return !isFilledForm ? (
    <div
      style={{
        width: "100%",
        height: screenHeight,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: screenWidth <= 768 ? "hidden" : "auto",
      }}
    >
      <div style={{ padding: "24px" }}>
        <PublicForm category={category} onFinishForm={onFinish} />
      </div>
    </div>
  ) : (
    <iframe
      src={`${UIKITURL}/social?darkMode=${
        theme === "dark" ? true : false
      }&primary=${colorCodeToString(primaryColor)}&category=${
        category ? category : loginForm.category
      }&userId=${loginForm.email}&displayName=${
        loginForm.displayName
      }&text=${colorCodeToString(textColor)}&subtitle=${colorCodeToString(
        textCaptionColor
      )}&background=${colorCodeToString(backgroundColor)}`}
      width={screenWidth}
      height={screenHeight}
      className="social-mobile-container"
    />
  );
};

export default SocialUIKitMobile;
