import { useEffect, useState } from 'react';
import './LayerItem.css';
import PageIcon from '../../icon/PageIcon';
import ArrowRightIcon from '../../icon/ArrowRightIcon';
import ComponentIcon from '../../icon/ComponentIcon';
import { NavigationItem } from '../../redux/slices/LayerDrawerSlice';
import { useLayerItems } from '../../hooks/useLayerItems';
import ElementIcon from '../../icon/ElementIcon';

export interface ILayerItemprops {
    item: NavigationItem;
    module: 'social' | 'chat'
}



const LayerItem = ({ item, module }: ILayerItemprops) => {

    const [isLayerOpen, setIsLayerOpen] = useState<boolean>(false);
    const [componentItems, setComponentItems] = useState<NavigationItem[]>([])

    const { getChildrenByParentId, updateCurrentSelect, checkIsSelect, checkIsExpand, openLayer, closeLayer } = useLayerItems()

    useEffect(() => {
        const children = getChildrenByParentId(item.uiReference, module)
        setComponentItems(children)
    }, [])


    const handleClickItem = (item: NavigationItem) => {
        updateCurrentSelect(item)
        openLayer(item)
    };
    const handleClickArrow = (item: NavigationItem) => {
        if (!checkIsExpand(item)) {
            openLayer(item)
        } else {
            closeLayer(item)
        }


    };


    return (
        <div>
            <div onClick={() => handleClickItem(item)} className={`layer-item-container ${checkIsSelect(item.uiReference) ? 'active-state' : 'inactive-state'}`}>
                <div className='layer-title-row'>
                    <PageIcon color={checkIsSelect(item.uiReference) ? '#3B41EC' : '#424242'} />
                    <p className='layer-name'>{item.name}</p>
                </div>
                <div className='arrow-icon-container' onClick={(e) => {
                    e.stopPropagation();
                    handleClickArrow(item);
                }}>
                   {/* <ArrowRightIcon className={`icon ${checkIsExpand(item) ? 'rotate' : ''} ${checkIsSelect(item.uiReference) ? 'active-state' : 'inactive-state'}`} color={checkIsSelect(item.uiReference) ? '#04be8c' : '#424242'} /> */}
                </div>
            </div>
            <div className={`component-container ${checkIsExpand(item) ? 'expand' : ''}`}>
                {componentItems.map((item, index) => {

                    const elements = getChildrenByParentId(item.uiReference, module)
                    return (
                        <div>

                            <div onClick={() => handleClickItem(item)} key={item.uiReference} className={`layer-item-container ${checkIsSelect(item.uiReference) ? 'active-state' : 'inactive-state'} component-layer`}>
                                <div className='layer-title-row'>
                                    <ComponentIcon color={checkIsSelect(item.uiReference) ? '#3B41EC' : '#424242'} />
                                    <p className='layer-name'>{item.name}</p>
                                </div>
                                <div className='arrow-icon-container' onClick={(e) => {
                                    e.stopPropagation();
                                    handleClickArrow(item);
                                }}>
                                    <ArrowRightIcon className={`icon ${checkIsExpand(item) ? 'rotate' : ''} ${checkIsSelect(item.uiReference) ? 'active-state' : 'inactive-state'}`} color={checkIsSelect(item.uiReference) ? '#04be8c' : '#424242'} />
                                </div>
                            </div>
                            <div className={`component-container ${checkIsExpand(item) ? 'expand' : ''}`}>
                                {
                                    elements.map(item =>
                                        <div key={item.uiReference} onClick={() => handleClickItem(item)}  className={`layer-item-container ${checkIsSelect(item.uiReference) ? 'active-state' : 'inactive-state'} element-layer`}>
                                            <div className='layer-title-row'>
                                                <ElementIcon color={checkIsSelect(item.uiReference) ? '#3B41EC' : '#424242'} />
                                                <p className='layer-name'>{item.name}</p>
                                            </div>

                                        </div>
                                    )
                                }
                            </div>
                        </div>

                    )
                })}
            </div>
        </div>
    );
};

export default LayerItem;
