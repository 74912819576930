import React from "react";

function MobileIcon({ width=24, height=24, ...props }) {
    return (
        <svg width={width} height={height}  viewBox="0 0 26 26" {...props}>
            <path d="M18.875 4.79333C19.8906 4.79333 20.75 5.65271 20.75 6.66833V22.9183C20.75 23.973 19.8906 24.7933 18.875 24.7933H10.125C9.07031 24.7933 8.25 23.973 8.25 22.9183V6.66833C8.25 5.65271 9.07031 4.79333 10.125 4.79333H18.875ZM14.5 23.5433C15.1641 23.5433 15.75 22.9965 15.75 22.2933C15.75 21.6293 15.1641 21.0433 14.5 21.0433C13.7969 21.0433 13.25 21.6293 13.25 22.2933C13.25 22.9965 13.7969 23.5433 14.5 23.5433ZM18.875 19.3246V7.13708C18.875 6.90271 18.6406 6.66833 18.4062 6.66833H10.5938C10.3203 6.66833 10.125 6.90271 10.125 7.13708V19.3246C10.125 19.598 10.3203 19.7933 10.5938 19.7933H18.4062C18.6406 19.7933 18.875 19.598 18.875 19.3246Z" />
        </svg>
    );
}

export default MobileIcon;
