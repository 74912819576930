import { ReactNode } from "react";
import "./CustomButton.css";
import { IconType } from "react-icons";
import Button from "@mui/joy/Button";

interface ICustomButtonprops {
  text?: string;
  onClick?: () => any;
  className?: string;
  rightIcon?: ReactNode;
  leftIcon?: ReactNode;
  alignIconRightMost?: boolean;
  disabled?: boolean; // Added disabled prop
  withBorder?: boolean;
  withShadow?: boolean;
  primary?: boolean;
  background?: string;
}
const CustomButton = ({
  text,
  onClick,
  className,
  rightIcon,
  leftIcon,
  withBorder = false,
  withShadow = false,
  primary = false,
  disabled,
  background = "#FFFFFF",
}: ICustomButtonprops) => {
  const handleOnClick = () => {
    if (!disabled) {
      onClick && onClick();
    }
  };
  return (
    <Button
      className={className}
      onClick={handleOnClick}
      startDecorator={leftIcon}
      style={{
        background: primary ? "#3B41EC" : background,
        color: primary ? "#FFFFFF" : "#424242",
        fontSize: 16,
        fontWeight: 600,
        padding: "12px 16px",
        boxShadow: !withShadow
          ? "none"
          : "0px 2px 4px 0px #60617029, 0px 0px 1px 0px #28293D0A",
        border: withBorder ? "1px solid #E5E5E5" : "none",
        height: 48,
        display: "flex",
        justifyContent: "center",
        borderRadius: 8,
        textAlign: "center",
      }}
      endDecorator={rightIcon}
    >
      {text}
    </Button>
  );
};

export default CustomButton;
