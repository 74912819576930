
import './ZoomButton.css';
import { GrZoomIn, GrZoomOut } from "react-icons/gr";


interface IZoomButtonprops {
    onClickZoomIn: () => void;
    onClickZoomOut: () => void;
}
const ZoomButton = ({ onClickZoomIn, onClickZoomOut }: IZoomButtonprops) => {

    const zoomIn = () => {
        onClickZoomIn && onClickZoomIn()
    }
    const zoomOut = () => {
        onClickZoomOut && onClickZoomOut()
    }
    return (
        <div className='zoom-button-container'>
            <div onClick={zoomIn} className='zoom-icon'>
            <GrZoomIn/>
            </div>
            <div className='icon-divider' />
             <div  onClick={zoomOut} className='zoom-icon'>
            <GrZoomOut/>
            </div>

        </div>
    );
};

export default ZoomButton;