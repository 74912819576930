import React, { useEffect, useState } from "react";
import "./UIKitMenu.css"
import CustomButton from '../CustomButtonV2/CustomButton';
import { FaChevronDown } from 'react-icons/fa6';
import SocialIcon from './social-icon.svg'
import { IDropDownOption, DropDown } from '../Dropdown/Dropdown';
import { useNavigate, useLocation } from 'react-router-dom';
import ContentIcon from "../../icon/ContentIcon";
import CodeIcon from "../../icon/CodeIcon";
import { useUIKitModule } from "../../hooks/useUIKitModule";

const UIKitMenu: React.FC = (): JSX.Element => {

    const { uikitOptions: options, selectedUIKit, updateCurrentSelect } = useUIKitModule();

    const [showDropDown, setShowDropDown] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] = useState<IDropDownOption>(options[0]);


    const toggleDropDown = () => {
        console.log("enter toggle drop")
        setShowDropDown(!showDropDown);
    };

    const handleSelection = (value: 'social' | 'chat' | 'story') => {
        const foundOption = options.find(option => option.value === value);
        setSelectedOption(foundOption)
        updateCurrentSelect(value)
    }
    const dismissHandler = (event: MouseEvent): void => {
        const dropdownButton = document.querySelector('.menu');
        const dropdownMenu = document.querySelector('.dropdown');
        const isButtonClicked = dropdownButton && dropdownButton.contains(event.target as Node);
        const isMenuClicked = dropdownMenu && dropdownMenu.contains(event.target as Node);

        if (!isButtonClicked && !isMenuClicked) {
            setShowDropDown(false);
        }
    };


    return (
        <>
            <div style={{ position: 'relative' }}>
                <CustomButton
                    className="uikit-menu"
                    text={selectedOption.text}
                    leftIcon={selectedOption?.icon}
                    onClick={toggleDropDown}
                    rightIcon={<FaChevronDown className="menu-arrow-icon" />}
                    alignIconRightMost={true}
                    withShadow

                />
                {/* <button
                    className={showDropDown ? "menu-active" : "menu"}
                    onClick={toggleDropDown}
                    onBlur={dismissHandler}
                >
                    <div>{selectCity ? "Select: " + selectCity : "Select ..."}</div>
                </button> */}
                {/* {showDropDown && ( */}
                <DropDown
                    options={options}
                    toggleDropDown={toggleDropDown}
                    optionSelection={handleSelection}
                    showDropDown={showDropDown}
                    selectedValue={selectedOption?.text}
                />
                {/* )} */}
            </div>
        </>
    );
};

export default UIKitMenu;
