import { Store, configureStore } from '@reduxjs/toolkit';

import LayerDrawerSlice from '../slices/LayerDrawerSlice';
import UIKitModuleSlice from '../slices/UIKitModuleSlice';
import NavbarSlice from '../slices/NavbarSlice';
import CustomizationSlice from '../slices/CustomizationSlice';

export const store: Store = configureStore({
  reducer: {
    layerDrawer: LayerDrawerSlice.reducer,
    uiKitModule: UIKitModuleSlice.reducer,
    navbar: NavbarSlice.reducer,
    customization: CustomizationSlice.reducer
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
