import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import layerDrawerSlice, { NavigationItem } from "../redux/slices/LayerDrawerSlice";

export const useLayerItems = () => {

    const { socialLayerItems, chatLayerItems, selectedItem, expandedItems } = useSelector((state: RootState) => state.layerDrawer);
    const { updateSelectedItem, openLayerItem, closeLayerItem } = layerDrawerSlice.actions
    const dispatch = useDispatch();

    const checkIsSelect = (uiReference: string) => {
        if (uiReference === selectedItem.uiReference) {
            return true;
        } else {
            return false;
        }

    }

    const getChildrenByParentId = (uiReference: string, module: 'social' | 'chat'): NavigationItem[] => {
        let children: NavigationItem[] = [];
        if (module === 'social') {
            const result = socialLayerItems.filter(item => item.parentUIReference === uiReference);
            children = result;
        }
        if (module === 'chat') {
            const result = chatLayerItems.filter(item => item.parentUIReference === uiReference);
            children = result;
        }
        return children;

    }

    const updateCurrentSelect = (item: NavigationItem) => {
        dispatch(updateSelectedItem(item))
    }

    const openLayer = (item: NavigationItem) => {
        dispatch(openLayerItem(item))
    }

    const closeLayer = (item: NavigationItem) => {
        dispatch(closeLayerItem(item))
    }

    const checkIsExpand = (item: NavigationItem) => {
        return expandedItems.includes(item.uiReference)
    }
    
    return {
        socialLayerItems,
        chatLayerItems,
        selectedItem,
        expandedItems,
        checkIsSelect,
        updateCurrentSelect,
        getChildrenByParentId,
        openLayer,
        closeLayer,
        checkIsExpand

    }

}