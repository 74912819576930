// App.js

import { useEffect, useState } from "react";
import { FiPlay } from "react-icons/fi";
import QRCode from "react-qr-code";
import CustomButton from "../CustomButtonV2/CustomButton";
import "./Navbar.css";
import React from "react";
import { Button, Modal, Switch } from "antd";
import PublicForm from "../PublicForm/PublicForm";
import { useNavigate } from "react-router-dom";
import SegmentedTabs from "../SegmentedTabs/SegmentedTabs";
import DesktopIcon from "../../icon/DesktopIcon";
import MobileIcon from "../../icon/MobileIcon";
import LightModeIcon from "../../icon/LightModeIcon";
import DarkModeIcon from "../../icon/DarkModeIcon";
import ZoomButton from "../ZoomButton/ZoomButton";
import ContentMenu from "../ContentMenu/ContentMenu";
import CodeMenu from "../CodeMenu/CodeMenu";
import PlayBtnIcon from "../../icon/PlayBtnIcon";
import RocketIcon from "../../icon/RocketIcon";
import { useNavbar } from "../../hooks/useNavbar";
import { useCustomization } from "../../hooks/useCustomization";

const Navbar = ({
  children,
  setFormValues,
  qrUrl,
  primaryColor,
  isPrivate,
  onZoomIn,
  onZoomOut,
}: any) => {
  const [showInspectCode, setShowInspectCode] = useState(false); // New state for toggling inspect code
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [resetConformModalOpen, setResetConfirmModalOpen] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [currentCategory, setCurrentCategory] = useState("");
  const [loading, setLoading] = useState(false);

  const [currentUrl, setCurrentUrl] = useState<string>("");
  const [shortenUrl, setShortenUrl] = useState<string>("");
  const { updateCustomization } = useCustomization();
  const navigate = useNavigate();

  const { updateCurrentThemeSelect, updatePlatform } = useNavbar();



  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleClose = () => {
    setIsModalVisible(false);
    setLoginModalOpen(false);
  };

  const handleCancelResetModal = () => {
    setResetConfirmModalOpen(false);
  };
  useEffect(() => {
    let hasLogin: boolean = false;
    const storedCategory = localStorage.getItem("category");
    const storedEmail = localStorage.getItem("email");
    const storedDisplayname = localStorage.getItem("displayName");

    if (storedCategory && storedEmail && storedDisplayname) {
      hasLogin = true;
      setFormValues(storedEmail, storedDisplayname, storedCategory, "social");
    }
    // if (!isPrivate && !hasLogin) {
    //   setLoginModalOpen(true);
    // }
  }, []);

  const onFinish = (values) => {
    setFormValues(
      values.email,
      values.displayName,
      values.category,
      values.module
    );
    setTimeout(() => {
      const storedCategory = localStorage.getItem("category");
      setCurrentCategory(storedCategory);
    }, 200);

    setLoading(true);
    if (values.module === "chat") {
      navigate(`/chat`);
    }
    handleClose();
  };

  const renameUrl = async () => {
    try {
      const timestamp: number = Date.now();
      const timeStampString = timestamp.toString();
      const response = await fetch("https://api.rebrandly.com/v1/links", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          apikey: "bb021b595c7a4edc8409176b66d077a8",
        },
        body: JSON.stringify({
          destination: qrUrl,
          domain: { fullName: "amity.social" },
          slashtag: `playground/${timeStampString}`,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const { shortUrl } = await response.json();
      setCurrentUrl(shortUrl);
      setShortenUrl(shortUrl);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (qrUrl) {
      renameUrl();
    }
  }, [qrUrl]);

  const onChangeSwitch = (checked: boolean) => {
    console.log(`switch to ${checked}`);
    if (checked) {
      setCurrentUrl(shortenUrl);
    } else {
      setCurrentUrl(
        `https://playground.amity.co/social?category=${currentCategory}&primary=${primaryColor}`
      );
    }
  };

  const resetContent = () => {
    setResetConfirmModalOpen(false);
    localStorage.setItem("category", "");
    localStorage.setItem("email", "");
    localStorage.setItem("displayName", "");
    setLoginModalOpen(true);
  };
  const openResetConfirmModal = () => {
    setResetConfirmModalOpen(true);
  };

  const handleZoomIn = () => {
    onZoomIn && onZoomIn();
  };
  const handleZoomOut = () => {
    onZoomOut && onZoomOut();
  };

  const handleThemeSwitch = (value: number) => {
    if (value === 0) {
      updateCurrentThemeSelect("light");
      updateCustomization("globalTheme", "background", "#ffffff");
      updateCustomization("globalTheme", "base", "#292b32");
      updateCustomization("globalTheme", "baseShade1", "#898e9e");
    }
    if (value === 1) {
      updateCurrentThemeSelect("dark");
      updateCustomization("globalTheme", "background", "#191919");
      updateCustomization("globalTheme", "base", "#ffffff");
      updateCustomization("globalTheme", "baseShade1", "#ffffff");
    }
  };

  const handlePlatformSwitch = (value: number) => {
    if (value === 0) {
      updatePlatform('mobile')
    }
    if (value === 1) {
      updatePlatform('desktop')
    }
  };
  return (
    <div className="nav-container">
      <div className="nav-row">
        <div className="group-item">
          <ContentMenu />
          <ZoomButton
            onClickZoomIn={handleZoomIn}
            onClickZoomOut={handleZoomOut}
          />
        </div>
        <div className="group-item">
          <SegmentedTabs
            onSwitchTab={handlePlatformSwitch}
            leftIcon={<MobileIcon height={24} width={24} />}
            rightIcon={<DesktopIcon height={24} width={24} />}
          />
          <SegmentedTabs
            onSwitchTab={handleThemeSwitch}
            leftIcon={<LightModeIcon height={24} width={24} />}
            rightIcon={<DarkModeIcon height={24} width={24} />}
          />
        </div>

        <div className="group-item">
          {/* <CustomButton className='customization-btn' text='Customization' color='secondary' icon={<FaWandMagicSparkles className='button-icon' />} onClick={handleCustomization} /> */}

          {/* {!isPrivate &&
            <CustomButton
              className="reset-btn"
              text="Reset Content"
              onClick={openResetConfirmModal}
            />
          } */}
          <CodeMenu />
          {/* <button className="play-uikit-btn">
            <PlayBtnIcon width={20} height={20} />
          </button>
          <CustomButton
            leftIcon={<RocketIcon />}
            text="Publish"
            onClick={openResetConfirmModal}
            primary
          /> */}

          {/* <CustomButton
            className="inspect-btn"
            text="View code"
            color="secondary"
            icon={
              <img
                src={BracketsCurly}
                className="drawer-title-icon"
                alt="Brackets Curly Icon"
              />
            }
            onClick={handleInspect}
          /> */}
          {/* QR: to be add later on in public playground */}
          {/* {!isPrivate && (
            <CustomButton
              className="live-preview-btn"
              text="Live Preview"
              onClick={showModal}
            />
          )} */}

          {/* <CustomButton className='refresh-btn' text='Refresh Content' color='primary' icon={<IoReloadCircle className='reset-icon' />} /> */}
        </div>
      </div>
      {children(showInspectCode, setShowInspectCode)}
      <Modal
        className="my-qr-modal"
        title="Live Preview"
        open={isModalVisible}
        onOk={handleClose}
        onCancel={handleClose}
        closable={true}
        footer={null} // This removes the default footer buttons
        width={320}
      >
        <div className="modal-qr-container">
          <QRCode className="qr-code" value={currentUrl} />
          <div className="switch-row">
            <Switch
              style={{ width: 30 }}
              defaultChecked
              onChange={onChangeSwitch}
            />
            <p>Quick access with a pre-filled email.</p>
          </div>

          <div className="qr-code-text-container">
            <FiPlay width={20} height={20} className="play-icon" />
            <p className="qr-code-text">
              <span className="bold-text">Scan the code</span> on your device
              <br />
              to view the prototype
            </p>
          </div>
        </div>
      </Modal>
      <Modal
        open={loginModalOpen}
        onOk={handleClose}
        onCancel={handleClose}
        closable={false}
        footer={null} // This removes the default footer buttons
        maskClosable={false}
      >
        <PublicForm onFinishForm={onFinish} />
      </Modal>
      <Modal
        className="billing-modal"
        width={600}
        open={resetConformModalOpen}
        title={
          <span style={{ fontSize: "20px" }}>Reset Content Confirmation</span>
        }
        onCancel={handleCancelResetModal}
        footer={null}
        closable={true}
        centered
      >
        <p style={{ fontSize: "14px" }}>
          This action will reset your content and require you to fill in the
          login form again. Are you sure you want to proceed?
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "24px",
          }}
        >
          <Button
            onClick={handleCancelResetModal}
            style={{
              background: "#EDEDED",
              width: "80px",
              height: "40px",
              fontWeight: "600",
              marginRight: "8px",
              border: "none",
              fontSize: "16px",
            }}

          >
            Cancel
          </Button>
          <Button
            onClick={resetContent}
            style={{
              background: "#06be8b",
              color: "#FFFFFF",
              height: "40px",
              width: "90px",
              border: "none",
              textAlign: "center",
              fontWeight: "600",
              fontSize: "16px",
            }}

          >
            Proceed
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default Navbar;
