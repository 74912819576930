// pages/Home.js
import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import "./SocialUIKit.css";
import InspectCodeDrawer from "../../components/Drawer/InspectCodeDrawer/InspectCodeDrawer";
import CustomizationDrawer from "../../components/Drawer/CustomizationDrawer/CustomizationDrawer";
import CustomButton from "../../components/CustomButtonV2/CustomButton";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import { Progress } from "antd";
import SocialUIKitMobile from "./SocialUIKitMobile";
import { MapInteractionCSS } from "react-map-interaction";
import LayerDrawer from "../../components/Drawer/LayerDrawer/LayerDrawer";
import UIKitMenu from "../../components/UIKitMenu/UIKitMenu";
import LayerIcon from "../../icon/LayerIcon";
import CustomizeIcon from "../../icon/CustomizeIcon";
import { useLayerItems } from "../../hooks/useLayerItems";
import { useUIKitModule } from "../../hooks/useUIKitModule";
import { useNavbar } from "../../hooks/useNavbar";
import { useCustomization } from "../../hooks/useCustomization";
import CodeDrawer from "../../components/Drawer/CodeDrawer/CodeDrawer";
import CodeSetUpDrawer from "../../components/Drawer/CodeSetUpDrawer/CodeSetupDrawer";
import ComingSoon from './coming-soon.svg'
const UIKITURL = process.env.REACT_APP_UIKIT_URL;

const SocialUIKit = ({ userId, apiKey, region, networkId }) => {

  const global = {
    uiReference: "*/*/*",
    parentUIReference: "none",
    name: "App",
  };

  const [loading, setLoading] = useState(false);
  const [videoReady, setVideoReady] = useState(false);
  const [showCustomization, setShowCustomization] = useState(true);
  const [showLayer, setShowLayer] = useState<boolean>(true);
  const [generatingStatus, setGeneratingStatus] = useState("");
  const [theme, setTheme] = useState("light");
  const [platform, setPlatform] = useState("mobile");
  const [primaryColor, setPrimaryColor] = useState("#1054de");
  const [textColor, setTextColor] = useState("#292B32");

  const [textCaptionColor, setTextCaptionColor] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("#FFFFFF");
  const [socialContainer, setSocialContainer] = useState(null);
  const [generatingPercentage, setGeneratingPercentage] = useState(0);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [isFilledForm, setIsFilledForm] = useState(false);
  const [publicUser, setPublicUser] = useState<Record<string, any>>();
  const [mapInteractionValue, setMapInteractionValue] = useState({
    scale: 1,
    translation: { x: screenWidth / 2 - 200, y: 10 },
  });
  const { selectedItem } = useLayerItems();
  const { selectedUIKit } = useUIKitModule();
  const { selectedTheme, selectedContent, selectedCodeMenu, selectedPlatform } = useNavbar();
  const { applyTheme } = useCustomization();
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setSocialContainer(document.querySelector(".social-main-container"));
  }, []);

  useEffect(() => {
    if (theme === "dark") {
      setBackgroundColor("#191919");
      setTextColor("#FFFFFF");
      setTextCaptionColor("#EBECEF");
    }
  }, [theme]);

  const handleCustomization = () => {
    setShowCustomization(!showCustomization);
  };

  const handleLayer = () => {
    setShowLayer(!showLayer);
  };

  const handleVideoLoad = () => {
    setVideoReady(true);
  };

  const colorCodeToString = (value: string) => {
    const newString = (value as string).replace(/#/g, "");
    return newString;
  };

  const fillUserInformation = (
    email: string,
    displayName: string,
    category: string,
    module: string
  ) => {
    setIsFilledForm(true);
    setPublicUser({
      email: email,
      displayName: displayName,
      category: category,
      module: module,
    });
  };

  const handleZoomIn = () => {
    const currentValue = { ...mapInteractionValue };
    currentValue.scale = currentValue.scale * 1.3;
    setMapInteractionValue(currentValue);
  };

  const handleZoomOut = () => {
    const currentValue = { ...mapInteractionValue };
    currentValue.scale = currentValue.scale / 1.3;
    setMapInteractionValue(currentValue);
  };

  const sendDataToIframe = (contentType, value) => {
    if (iframeRef.current && iframeRef.current.contentWindow) {
      iframeRef.current.contentWindow.postMessage(
        { type: "FROM_PARENT", payload: { type: contentType, value: value } },
        "*"
      );
    }
  };

  // Function to handle messages from iframe
  const handleIframeMessage = (event: MessageEvent) => {
    if (event.origin !== window.location.origin) return; // Optionally, validate the origin
    if (event.data.type === "FROM_IFRAME") {
      console.log("Message from iframe:", event.data.payload);
    }
  };

  useEffect(() => {
    window.addEventListener("message", handleIframeMessage);
    return () => {
      window.removeEventListener("message", handleIframeMessage);
    };
  }, []);

  useEffect(() => {
    sendDataToIframe("theme", selectedTheme);
  }, [selectedTheme]);

  useEffect(() => {
    sendDataToIframe("category", selectedContent);
  }, [selectedContent]);

  useEffect(() => {
    sendDataToIframe("saveTheme", applyTheme);
  }, [applyTheme]);

  useEffect(() => {
    sendDataToIframe("selectedPage", selectedItem);
  }, [selectedItem]);

  return screenWidth > 800 ? (
    <Navbar
      networkId={networkId}
      setFormValues={fillUserInformation}
      qrUrl={`https://reactnative.uikit.amity.co/${selectedUIKit}?darkMode=${selectedTheme === "dark" ? true : false
        }&primary=${colorCodeToString(
          primaryColor
        )}&${`apiKey=${apiKey}`}&userId=${userId}&displayName=${userId}&apiRegion=${region}&text=${colorCodeToString(
          textColor
        )}&subtitle=${colorCodeToString(
          textCaptionColor
        )}&background=${colorCodeToString(backgroundColor)}`}
      primaryColor={colorCodeToString(primaryColor)}
      onZoomIn={handleZoomIn}
      onZoomOut={handleZoomOut}
    >
      {(showInspectCode, setShowInspectCode) => (
        <div className="all-social-page-area" style={{ height: screenHeight }}>
          <div className="social-customization-btn-container">
            <CustomButton
              text=""
              onClick={handleCustomization}
              leftIcon={<CustomizeIcon />}
              alignIconRightMost={false}
              withShadow
              rightIcon={<FaChevronLeft className="menu-arrow-icon" />}
            />
          </div>
          <div className="layer-btn-container">
            <CustomButton
              // className="layer-btn"
              withShadow
              text=""
              onClick={handleLayer}
              alignIconRightMost={false}
              rightIcon={<FaChevronRight className="menu-arrow-icon" />}
              leftIcon={<LayerIcon />}
            />
          </div>
          {selectedItem.uiReference == global.uiReference && (
            <div
              style={{ left: showLayer ? 350 : 130 }}
              className="layer-btn-container-2"
            >
              <UIKitMenu />
            </div>
          )}

          <MapInteractionCSS
            value={mapInteractionValue}
            onChange={(value) => setMapInteractionValue(value)}
          >
            <div>
              {!loading ?
                selectedPlatform === 'mobile' ? <div className="social-container">
                  <iframe
                    ref={iframeRef}
                    src={`${UIKITURL}/${selectedUIKit}?category=${selectedContent}&userId=${userId}&displayName=${userId}`}
                    width="415"
                    height="745"
                    className={`social-iframe`}
                    onLoad={() => sendDataToIframe("theme", selectedTheme)}
                  />
                </div> :
                  <div className='coming-soon-container'>
                    <div>
                      <img src={ComingSoon} />
                      <p className='description'>Coming soon...</p>
                      <p className='subtitle'>Please keep in touch for the Desktop view update</p>
                    </div>

                  </div>

                : (
                  <div className="loading-container">
                    <div
                      className={`loading-modal-content ${videoReady ? "" : "hidden"
                        }`}
                    >
                      <div className="video-wrap">
                        <video
                          autoPlay
                          width="188"
                          height="156"
                          loop
                          onLoadedData={handleVideoLoad}
                        >
                          <source
                            src="https://api.sg.amity.co/api/v3/files/65b75b03265602bce94d8d6a/download"
                            type="video/mp4"
                          />
                        </video>
                      </div>
                      <div
                        style={{
                          width: "50%",
                          paddingLeft: "55px",
                          paddingRight: "55px",
                          marginTop: "16px",
                          height: "4px",
                          marginBottom: "16px",
                        }}
                      >
                        <Progress
                          strokeColor="#3B41EC"
                          percent={generatingPercentage}
                          size="small"
                          status="active"
                          showInfo={true}
                        />
                      </div>
                      <p>{generatingStatus || "Initializing..."} </p>
                    </div>
                  </div>
                )}

              {/* <InspectCodeDrawer
                showInspectCode={selectedCodeMenu ==='setup'}
                module="social"
                setShowInspectCode={setShowInspectCode}
                primaryColor={primaryColor}
                getContainer={socialContainer}
                apiKey={apiKey}
              /> */}
              <CustomizationDrawer
                showCustomization={showCustomization}
                setShowCustomization={setShowCustomization}
              // setTheme={setTheme}
              // setPlatform={setPlatform}
              // setPrimaryColor={setPrimaryColor}
              // setSecondaryColor={setTextColor}
              // setTextCaptionColor={setTextCaptionColor}
              // setBackgroundColor={setBackgroundColor}
              // getContainer={socialContainer}
              // Pass other required props for CustomizationDrawer
              />
              <LayerDrawer showLayer={showLayer} setShowLayer={setShowLayer} />
              <CodeDrawer showDrawer={selectedCodeMenu === 'json'} />
              <CodeSetUpDrawer showDrawer={selectedCodeMenu === 'setup'} />
            </div>
          </MapInteractionCSS>
        </div>
      )}
    </Navbar>
  ) : (
    <SocialUIKitMobile />
  );
};

export default SocialUIKit;
