import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import Social from "./screens/SocialUIKit/SocialUIKit";
import Chat from "./screens/ChatUIKit/ChatUIKit";
import LiveChat from "./screens/LiveChatUIKit/LiveChatUIKit";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { checkConsoleCookies } from "./utils/cookieServices";
import { useNavbar } from "./hooks/useNavbar";
import "@amityco/ulta-ui-kit/dist/index.css";
import { AmityUiKitProvider, AmityUiKitSocial } from "@amityco/ulta-ui-kit";

interface ISpecialApp {
  apiKey: string;
  apiRegion: string;
  faqCommunityId?: string;
  newsCommunityId?: string;
  username?: string;


}
function App() {
  const [loading, setLoading] = useState(true);
  const [applications, setApplications] = useState([]);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [specialApplication, setSpecialApplication] = useState<ISpecialApp>();
  const [isSpecialApp, setIsSpecialApp] = useState<boolean>(false)

  const [userIndex, setUserIndex] = useState<number>(2)
  const { selectedContent } = useNavbar()
  const [isSecuredMode, setIsSecuredMode] = useState(false);
  console.log('isSecuredMode: ', isSecuredMode);


  const userIndexSelection = () => {
    switch (selectedContent) {
      case 'travel':
        setUserIndex(2)
        break;
      case 'sport':
        setUserIndex(3)
        break;
      case 'fitness':
        setUserIndex(4)
        break;
      case 'gaming':
        setUserIndex(5)
        break;
      case 'automotive':
        setUserIndex(1)
        break;
      default:
        break;
    }
  }
  useEffect(() => {
    userIndexSelection()
  }, [selectedContent])

  const fetchData = async () => {
    try {
      const temp = await checkConsoleCookies("us");
      if (temp?.length > 0) {
        const [{ isSecureModeEnabled, apiKey, username }] = temp;

        setIsSecuredMode(isSecureModeEnabled);
        setSpecialApplication({ apiKey, apiRegion: 'us', username });

        if (isSecureModeEnabled) {
          console.log("Secure mode enabled:", isSecureModeEnabled);
        }

        // Ensure the environment variable exists and is valid JSON
        const specialApps = process.env.REACT_APP_ASC_SPECIAL_APPs
          ? JSON.parse(process.env.REACT_APP_ASC_SPECIAL_APPs)
          : [];

        const indexOfApp = specialApps.findIndex(item => item.apiKey === apiKey);

        if (indexOfApp !== -1) {
          setSpecialApplication(prev => ({
            ...prev,
            newsCommunityId: specialApps[indexOfApp]?.newsCommunityId,
            faqCommunityId: specialApps[indexOfApp]?.faqCommunityId,
          }));
          setIsSpecialApp(true);
        }
      }
      setLoading(false)
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);




  useEffect(() => {
    const userBranch = process.env.REACT_APP_USER_BRANCH;

    switch (userBranch) {
      case "dev":
        setApplications(JSON.parse(process.env.REACT_APP_ASC_APPLICATIONS));
        break;
      case "local":
        setApplications(JSON.parse(process.env.REACT_APP_ASC_APPLICATIONS));
        break;
      default:
        break;
    }
  }, [selectedContent]);

  useEffect(() => {
    if (applications.length > 0) {
      const { apiKey, region, networkId, username, adminToken, name } =
        applications[userIndex];
      setSelectedApplication({
        apiKey,
        region,
        networkId,
        username,
        adminToken,
        name
      });
    }
  }, [applications]);

  return (
    <div>
      {!loading && selectedApplication && !isSpecialApp ? (
        <div className="App">
          <Routes>
            <Route
              path="/"
              element={<Navigate to={`/social?region=`} replace />}
            />

            <Route
              path="/social"
              element={
                <Social
                  userId={selectedApplication.username} // Since the form is removed, ensure you handle user identification appropriately
                  apiKey={selectedApplication.apiKey}
                  region={selectedApplication.region}
                  networkId={selectedApplication.networkId}
                />
              }
            />

            <Route
              path="/chat"
              element={
                <Chat
                  userId={selectedApplication.username} // Since the form is removed, ensure you handle user identification appropriately
                  apiKey={selectedApplication.apiKey}
                  region={selectedApplication.region}
                  networkId={selectedApplication.networkId}
                />
              }
            />

            <Route
              path="/live-chat"
              element={
                <LiveChat
                  userId={selectedApplication.username} // Since the form is removed, ensure you handle user identification appropriately
                  apiKey={selectedApplication.apiKey}
                  region={selectedApplication.apiRegion}
                  networkId={selectedApplication.networkId}
                />
              }
            />
          </Routes>
        </div>
      ) : (isSpecialApp && specialApplication) ?
        <div style={{ width: '100%', height: '100dvh' }}>
          <AmityUiKitProvider

            apiKey={specialApplication?.apiKey}
            userId={specialApplication?.username ?? 'ultra-user'}
            displayName={'Ulta Beauty Admin'}
            apiRegion={specialApplication?.apiRegion}
            ultaConfig={{ faqCommunityId: specialApplication?.faqCommunityId, newsCommunityId: specialApplication?.newsCommunityId, termsAndConditionsUrl:'https://www.ulta.com/foo',privacyAndPolicyUrl:'https://www.ulta.com/bar' }}

          >
            <AmityUiKitSocial />
          </AmityUiKitProvider>
        </div>
        : (
          <div className="center">
            <div className="center">
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 48, fontWeight: 600, color: "#3B41EC" }}
                    spin
                  />
                }
              />
            </div>
          </div>
        )}
    </div>
  );
}

export default App;
