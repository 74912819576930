// components/InspectCodeDrawer.tsx
import { Button, ColorPickerProps, Drawer, GetProp, Tabs } from 'antd';
import { FaXmark } from 'react-icons/fa6';
import BracketsCurly from '../../../BracketsCurly.svg'
import "./InspectCodeDrawer.css";
import { CopyBlock, dracula, monokai, vs2015 } from "react-code-blocks";
import TabPane from 'antd/es/tabs/TabPane';
import React from 'react';
import { iOSSocialCodeSnippet, androidSocialCodeSnippet, webSocialCodeSnippet, reactNativeSocialCodeSnippet, flutterSocialCodeSnippet, androidChatCodeSnippet, flutterChatCodeSnippet, iOSChatCodeSnippet, reactNativeChatCodeSnippet, webChatCodeSnippet } from '../../../utils/codeSnippets';

type Color = GetProp<ColorPickerProps, "value">;

interface InspectCodeDrawerProps {
    showInspectCode: boolean;
    module: string;
    setShowInspectCode: (show: boolean) => void;
    primaryColor: Color;
    getContainer?: any | null;
    apiKey: string;
}

const languageMap = {
    iOS: "swift",
    Android: "kotlin",
    Web: "javascript",
    "React Native": "javascript",
};


export const CodeBlock = ({ platform, module, primaryColor, apiKey }) => {

    let codeSnippet = '';

    if (module === "social") {
        switch (platform) {
            case 'iOS':
                codeSnippet = iOSSocialCodeSnippet;
                break;
            case 'Android':
                codeSnippet = androidSocialCodeSnippet;
                break;
            case 'Web':
                codeSnippet = webSocialCodeSnippet;
                break;
            case 'React Native':
                codeSnippet = reactNativeSocialCodeSnippet;
                break;
            case 'Flutter':
                codeSnippet = flutterSocialCodeSnippet;
                break;
            default:
                codeSnippet = 'Platform not supported.';
        }
    }
    else {
        switch (platform) {
            case 'iOS':
                codeSnippet = iOSChatCodeSnippet;
                break;
            case 'Android':
                codeSnippet = androidChatCodeSnippet;
                break;
            case 'Web':
                codeSnippet = webChatCodeSnippet;
                break;
            case 'React Native':
                codeSnippet = reactNativeChatCodeSnippet;
                break;
            case 'Flutter':
                codeSnippet = flutterChatCodeSnippet;
                break;
            default:
                codeSnippet = 'Platform not supported.';
        }
    }



    let themedCodeSnippet = codeSnippet.replace(/THEME_PRIMARY_COLOR/g, primaryColor);
    themedCodeSnippet = themedCodeSnippet.replace(/YOUR_API_KEY/g, apiKey);

    const language = languageMap[platform];
    console.log("checking language " + language + " ---- " + platform)
    return (
        <div>
            {codeSnippet ? (
                <CopyBlock
                    text={themedCodeSnippet}
                    language={language}
                    theme={dracula}
                    wrapLongLines={true}
                />
            ) : (
                <p>Coming Soon!</p>
            )}
        </div>
    );
};


const InspectCodeDrawer: React.FC<InspectCodeDrawerProps> = ({ showInspectCode, setShowInspectCode, primaryColor, getContainer, apiKey }) => {
    const primaryColorString = React.useMemo<string>(
        () =>
            typeof primaryColor === "string"
                ? primaryColor
                : '',
        [primaryColor]
    );
    return <Drawer
        className='inspect-code-ant-drawer-header'
        title={
            <div style={{ display: "flex" }}>
                <span className="inspect-code-drawer-title">
                    <img
                        src={BracketsCurly}
                        className="drawer-title-icon"
                        alt="Brackets Curly Icon"
                    />
                    View code
                </span>
                <Button
                    onClick={() => setShowInspectCode(false)}
                    className="drawer-close-button"
                >
                    <FaXmark />
                </Button>
            </div>
        }
        placement="right"
        closable={false}
        onClose={() => setShowInspectCode(false)}
        getContainer={() => getContainer ?? document.body}
        style={{ position: "absolute" }}
        rootStyle={{ position: "absolute", top: 81 }}
        open={showInspectCode}
        width={700}
    >
        <div className="drawer-body">


            <div className="code-snippet-tabs-section">
                <Tabs
                    tabBarStyle={{ color: "#8c97a5" }}
                    className="custom-tab-bar"
                    animated={false}
                    tabBarGutter={0}
                    type="card"
                    defaultActiveKey={Object.keys(languageMap)[0]}
                >
                    {Object.entries(languageMap).map(([platform]) => (
                        <TabPane forceRender={true} tab={platform} key={platform}>
                            <div className="code-snippet">
                                <CodeBlock platform={platform} module={module} primaryColor={primaryColorString} apiKey={apiKey} />
                            </div>
                        </TabPane>
                    ))}
                </Tabs>
            </div>
            <p className="drawer-subtitle">
                If you have not integrated Amity UIKit yet, please refer to the
                documentation for your specific platform.
            </p>
            <ul>
                <li>
                    <a
                        className="link-color"
                        href="https://docs.amity.co/amity-uikit/uikit-v4-beta/installation-guide/ios"
                        target="_blank"
                        rel="noreferrer"
                    >
                        iOS
                    </a>
                </li>
                <li>
                    <a
                        className="link-color"
                        href="https://docs.amity.co/amity-uikit/uikit-v4-beta/installation-guide/android"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Android
                    </a>
                </li>
                <li>
                    <a
                        className="link-color"
                        href="https://docs.amity.co/amity-uikit/web/web-uikit-installation-guide"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Web
                    </a>
                </li>
                <li>
                    <a
                        className="link-color"
                        href="https://docs.amity.co/amity-uikit/react-native-beta/setup-and-authentication"
                        target="_blank"
                        rel="noreferrer"
                    >
                        React Native
                    </a>
                </li>
            </ul>
        </div>
    </Drawer>
};

export default InspectCodeDrawer;
