import React, { useEffect, useState } from "react";
import "./ContentMenu.css"
import CustomButton from '../CustomButtonV2/CustomButton';
import { FaChevronDown } from 'react-icons/fa6';
import SocialIcon from './social-icon.svg'
import { IDropDownOption, DropDown } from '../Dropdown/Dropdown';
import { useNavigate, useLocation } from 'react-router-dom';
import ContentIcon from "../../icon/ContentIcon";
import { MdOutlineSportsBasketball, MdCardTravel } from "react-icons/md";
import { IoMdFitness, IoLogoGameControllerB } from "react-icons/io";
import { IoCarSport, IoPeople } from "react-icons/io5";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { useNavbar } from "../../hooks/useNavbar";

const ContentMenu: React.FC = (): JSX.Element => {
    const options = [
        {
            icon: <div className="content-icon"><MdCardTravel /></div>,
            text: 'Travel',
            value: 'travel'
        },
        {
            icon: <div className="content-icon"><MdOutlineSportsBasketball /></div>,
            text: 'Sport',
            value: 'sport'
        },
        {
            icon: <div className="content-icon"><IoMdFitness /></div>,
            text: 'Fitness',
            value: 'fitness'
        },
        {
            icon: <div className="content-icon"><IoLogoGameControllerB /></div>,
            text: 'Gaming',
            value: 'gaming'
        },
        {
            icon: <div className="content-icon"><IoCarSport /></div>,
            text: 'Automotive',
            value: 'automotive'
        },
        // {
        //     icon: <div className="content-icon"><FaMoneyBillTrendUp /></div>,
        //     text: 'Financial',
        //     value: 'financial'
        // },
    ]

    const { selectedContent, updateContentSelect } = useNavbar()

    const [showDropDown, setShowDropDown] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] = useState<IDropDownOption>(options[0]);





    const toggleDropDown = () => {
        console.log("enter toggle drop")
        setShowDropDown(!showDropDown);
    };


    const dismissHandler = (event: MouseEvent): void => {
        const dropdownButton = document.querySelector('.menu');
        const dropdownMenu = document.querySelector('.dropdown');
        const isButtonClicked = dropdownButton && dropdownButton.contains(event.target as Node);
        const isMenuClicked = dropdownMenu && dropdownMenu.contains(event.target as Node);

        if (!isButtonClicked && !isMenuClicked) {
            setShowDropDown(false);
        }
    };

    const handleSelection = (value: string) => {
        const foundOption = options.find(option => option.value === value);
        setSelectedOption(foundOption)
        updateContentSelect(value)
    }

    return (
        <>
            <div style={{ position: 'relative' }} >
                <CustomButton
                    className="content-menu"
                    text={selectedOption.text}
                    leftIcon={selectedOption.icon}
                    onClick={toggleDropDown}
                    rightIcon={<FaChevronDown className="menu-down-icon" />}
                    alignIconRightMost={true}
                    withBorder
                />
                {/* <button
                    className={showDropDown ? "menu-active" : "menu"}
                    onClick={toggleDropDown}
                    onBlur={dismissHandler}
                >
                    <div>{selectCity ? "Select: " + selectCity : "Select ..."}</div>
                </button> */}
                {/* {showDropDown && ( */}
                <DropDown
                    options={options}
                    toggleDropDown={toggleDropDown}
                    optionSelection={handleSelection}
                    showDropDown={showDropDown}
                    selectedValue={selectedOption?.text}
                    
                />
                {/* )} */}
            </div>
        </>
    );
};

export default ContentMenu;
