import React from "react";

function CustomizeIcon({ width = 25, height = 25, ...props }) {
    return (
        <svg width={width} height={height} viewBox="0 0 25 25" {...props}>
            <path d="M20.4785 14.9961C20.4785 14.9961 22.7285 18.2656 22.7285 19.4961C22.7285 20.7617 21.709 21.7461 20.4785 21.7461C19.2129 21.7461 18.2285 20.7617 18.2285 19.4961C18.2285 18.2656 20.4785 14.9961 20.4785 14.9961ZM20.127 11.4102C20.584 11.832 20.584 12.5352 20.127 12.9922L12.3574 20.7617C11.6895 21.4297 10.8105 21.7461 9.9668 21.7461C9.08789 21.7461 8.24414 21.4297 7.57617 20.7617L3.46289 16.6484C2.12695 15.3477 2.12695 13.2031 3.46289 11.8672L6.76758 8.5625L3.74414 5.50391C3.5332 5.29297 3.5332 4.94141 3.74414 4.73047L4.55273 3.92188C4.76367 3.71094 5.11523 3.71094 5.32617 3.92188L8.38477 6.94531L11.2324 4.09766C11.4434 3.88672 11.7598 3.74609 12.041 3.74609C12.3223 3.74609 12.6035 3.88672 12.8145 4.09766L20.127 11.4102ZM16.0488 13.9062L17.7363 12.1836L12.041 6.48828L9.9668 8.5625L12.0059 10.6016C12.4629 11.0586 12.4629 11.7617 12.0059 12.2188C11.584 12.6406 10.8809 12.6406 10.4238 12.2188L8.38477 10.1445L5.50195 13.0273L5.04492 13.4844C4.9043 13.625 4.83398 13.7656 4.76367 13.9062H16.0488Z" fill="#424242" />
        </svg>


    );
}

export default CustomizeIcon;
