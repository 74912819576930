import React, { useEffect, useState } from "react";
import "./Codemenu.css"
import CustomButton from '../CustomButtonV2/CustomButton';
import { FaChevronDown } from 'react-icons/fa6';
import SocialIcon from './social-icon.svg'
import { IDropDownOption, DropDown } from '../Dropdown/Dropdown';
import { useNavigate, useLocation } from 'react-router-dom';
import ContentIcon from "../../icon/ContentIcon";
import CodeIcon from "../../icon/CodeIcon";
import RocketIcon from "../../icon/RocketIcon";
import JsonIcon from "../../icon/JsonIcon";
import { useNavbar } from "../../hooks/useNavbar";

const CodeMenu: React.FC = (): JSX.Element => {
    const options = [
        {
            icon: <RocketIcon color="#757575" />,
            text: 'UIKit setup',
            value: 'setup'
        },
        {
            icon: <JsonIcon />,
            text: 'Edit JSON',
            value: 'json'
        },
    ]


    const [showDropDown, setShowDropDown] = useState<boolean>(false);
    const [selectedOption] = useState<IDropDownOption>(options[0]);
    const { updateCodeMenu } = useNavbar()





    const toggleDropDown = () => {
        console.log("enter toggle drop")
        setShowDropDown(!showDropDown);
    };

    const handleSelection = (value: string) => {
        updateCodeMenu(value)
    }


    return (
        <>
            <div style={{ position: 'relative' }}>
                <CustomButton
                    text={'Code'}
                    leftIcon={<CodeIcon />}
                    onClick={toggleDropDown}
                    rightIcon={<FaChevronDown className="menu-down-icon" />}
                    alignIconRightMost={true}
                    withBorder
                />

                <DropDown
                    options={options}
                    toggleDropDown={toggleDropDown}
                    style={{ position: 'absolute', left: -100 }}
                    showDropDown={showDropDown}
                    selectedValue={selectedOption?.text}
                    optionSelection={handleSelection }
                    isHideCheckMark
                />
                {/* )} */}
            </div>
        </>
    );
};

export default CodeMenu;
