import React from "react";

function DesktopIcon({ width = 24, height = 24, ...props }) {
    return (
        <svg fill="currentColor" width={width} height={height}  viewBox="0 0 26 26"  {...props}>
            <path d="M23.875 4.79333C24.8906 4.79333 25.75 5.65271 25.75 6.66833V19.1683C25.75 20.223 24.8906 21.0433 23.875 21.0433H16.375L17 22.9183H19.8125C20.3203 22.9183 20.75 23.348 20.75 23.8558C20.75 24.4027 20.3203 24.7933 19.8125 24.7933H9.1875C8.64062 24.7933 8.25 24.4027 8.25 23.8558C8.25 23.348 8.64062 22.9183 9.1875 22.9183H12L12.625 21.0433H5.125C4.07031 21.0433 3.25 20.223 3.25 19.1683V6.66833C3.25 5.65271 4.07031 4.79333 5.125 4.79333H23.875ZM23.25 18.5433V7.29333H5.75V18.5433H23.25Z" />
        </svg>

    );
}
export default DesktopIcon;