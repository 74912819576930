import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import UIKitModuleSlice from "../redux/slices/UIKitModuleSlice";

export const useUIKitModule = () => {

    const { uikitOptions, selectedUIKit } = useSelector((state: RootState) => state.uiKitModule);
    const { updateSelectedUIKit } = UIKitModuleSlice.actions
    const dispatch = useDispatch();

    const updateCurrentSelect = (item: 'social' | 'chat' | 'story') => {
        dispatch(updateSelectedUIKit(item))
    }

    return {
        uikitOptions,
        selectedUIKit,
        updateCurrentSelect

    }

}