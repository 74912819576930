// components/CustomizationDrawer.tsx
import React, { useEffect, useRef, useState } from 'react';
import { FaWandMagicSparkles, FaMoon, FaChevronRight } from 'react-icons/fa6';

import SegmentedControl from '../../SegmentedControl/SegmentedControl';
import LightBulbIcon from '../../../icon/LightBulbIcon';

import ColorSwatch from '../../ColorSwatch/ColorSwatch';
import CustomButton from '../../CustomButtonV2/CustomButton';
import { useLocation } from 'react-router-dom';
import { Tab } from '@headlessui/react'
import { Drawer, Tooltip } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import "./CustomizationDrawer.css";
import ColorPicker, { useColorPicker } from 'react-best-gradient-color-picker'
import ColorInput from '../../ColorInput/ColorInput';
import CustomizeIcon from '../../../icon/CustomizeIcon';
import { useCustomization } from '../../../hooks/useCustomization';


interface CustomizationDrawerProps {
    showCustomization: boolean;
    setShowCustomization: (show: boolean) => void;
    // setPrimaryColor: (color: string) => void;
    // setTheme?: (theme: string) => void;
    // setPlatform?: (platform: string) => void;
    // setSecondaryColor?: (color: string) => void;
    // setTextCaptionColor?: (color: string) => void;
    // setBackgroundColor?: (color: string) => void;
    // getContainer?: any | null;
}


const CustomizationDrawer: React.FC<CustomizationDrawerProps> = ({ showCustomization, setShowCustomization }) => {

    const { globalTheme, discardGlobalTheme, handleApplyTheme } = useCustomization()


    const reset = () => {
        discardGlobalTheme()
    }
    const apply = () => {
        handleApplyTheme(globalTheme)
    }

    return (
        <Drawer
            key={2}
            PaperProps={{
                style: {
                    background: 'transparent',
                    width: 320,
                    marginTop: 83,
                    boxShadow: 'none',
                    zIndex: 2,
                    paddingLeft: 12,
                    overflow: 'hidden'
                    
                    //style props for your drawer menu here
                },
            }}
            
            ModalProps={{
                keepMounted: true,
                hideBackdrop: true,
                style: {
                    position: "absolute",
                    bottom: 100,
                    left: 'unset',
                    right: 'unset'
                }
            }}
            open={showCustomization}
            anchor="right"
            disableEnforceFocus

        >
            <div onClick={() => setShowCustomization(false)} className='close-customization-container'>

                <Tooltip title='Close Sidebar' placement='left-end' arrow followCursor>
                    <div>
                        <div className="left-border-container" />
                        <FaChevronRight className='sticky-button' />
                    </div>
                </Tooltip>

            </div>



            <div className='customization-drawer-container'>
                <div className='customization-title-row'>
                    <CustomizeIcon />
                    <p className='customization-title-text'>Global Customization</p>
                </div>


                <ColorInput title='Primary' value={globalTheme.primary} themeKey='globalTheme' valueKey='primary' />
                <ColorInput title='Background' value={globalTheme.background} themeKey='globalTheme' valueKey='background' />
                <ColorInput title='Border' value={globalTheme.border} themeKey='globalTheme' valueKey='border' />
                <ColorInput title='Title' value={globalTheme.base} themeKey='globalTheme' valueKey='base' position='top' />
                <ColorInput title='Subtitle' value={globalTheme.baseShade1} themeKey='globalTheme' valueKey='baseShade1' position='top' />

                <div className='action-button-row'>
                    <CustomButton onClick={reset} background='#F5F5F5' className='action-button' text='Discard' />
                    <CustomButton onClick={apply} className='action-button' text='Apply' primary />
                </div>

            </div>


        </Drawer >
    )
};

export default CustomizationDrawer;
