import axios from "axios";
import { API } from "aws-amplify";

export const consoleCookiesCallAdminAPI = async (region) => {
  try {
    const apis = [
      { id: "sg", url: "https://api.sg.amity.co/admin/v1/admin" },
      { id: "us", url: "https://api.us.amity.co/admin/v1/admin" },
      { id: "eu", url: "https://api.eu.amity.co/admin/v1/admin" },
    ];

    // If region is specified, attempt to find and use the matching API
    if (region) {
      for (const api of apis) {
        if (api.id === region.toLowerCase()) {
          try {
            const response = await axios.get(api.url, {
    
              withCredentials: true,
            });
            if (response && response.status === 200) {
              return [
                {
                  region: api.id,
                  user: response.data.user,
                  apiKey: await getAPIKey(api.id),
                  isSecureModeEnabled: await getSecureModeEnabled(api.id),
                  isSocialEnabled: response.data.networkSetting.social.enabled,
                },
              ];
            }
          } catch (error) {
            console.error("An error occurred:", error);
            // If you want to stop the iteration on error, break or return here
          }
          break; // Exit after attempting the matched API call
        }
      }
    }

    // If no region is specified or the specified region's call fails, proceed with all APIs
    const results = await Promise.all(
      apis.map(async (api) => {
        try {
          const response = await axios.get(api.url, { withCredentials: true });
          return { id: api.id, response, error: null };
        } catch (error) {
          console.error("An error occurred:", error);
          return { id: api.id, response: null, error };
        }
      })
    );

    // Filter and process successful results
    let firstSuccessfulResult = null; // Initialize to null, indicating no successful result has been found yet

    for (const result of results) {
      if (result.response && result.response.status === 200) {
        // Once we find a successful result, we fetch the API key and construct the final object
        try {
          const apiKey = await getAPIKey(result.id);
          firstSuccessfulResult = {
            region: result.id,
            user: result.response.data.user,
            apiKey: apiKey,
          };
          break; // Exit the loop since we've found our first successful result
        } catch (error) {
          console.error("Error fetching API key:", error);
          // Decide how to handle the error. You might choose to continue to the next result, or handle differently
        }
      }
    }

    return firstSuccessfulResult ? firstSuccessfulResult : false;
  } catch (error) {
    console.error("An outer error occurred:", error);
    return false;
  }
};

export async function getAPIKey(region: string) {
  try {
    const resp = await axios.get(
      `https://api.${region}.amity.co/admin/v1/network/apikey`,
      { withCredentials: true }
    );
    return resp.data.apiKey;
  } catch (error) {
    console.error("An error occurred:", error);
    return false;
  }
}

export async function getSecureModeEnabled(region: string) {
  try {
    const resp = await axios.get(
      `https://api.${region}.amity.co/api/v3/networks/me`,
      { withCredentials: true }
    );
    return resp.data.useSecureAuthentication;
  } catch (error) {
    console.error("An error occurred:", error);
    return false;
  }
}

export async function createCategory(
  name: string,
  accessToken: string,
  region: string,
  networkId: string,
  userId: string,
  apiKey: string
) {
  const resp = await API.post(
    "eventHandler",
    `/api/v1/content-simulation/generateSocial`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: { name, region, networkId, userId, apiKey },
    }
  );
  return resp;
}

export async function createChatContent(
  name: string,
  accessToken: string,
  region: string,
  networkId: string,
  userId: string,
  apiKey: string
) {
  const resp = await API.post(
    "eventHandler",
    `/api/v1/content-simulation/generateChat`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: { name, region, networkId, userId, apiKey },
    }
  );
  return resp;
}

export async function getGeneratingStatus({
  accessToken,
  categoryId,
  networkId,
}) {
  let path = `/api/v1/content-simulation/getGeneratingStatus?categoryId=${categoryId}`;
  if (networkId) path += `&networkId=${networkId}`;
  const resp = await API.get("eventHandler", path, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  // Cut unique id suffix out)
  return resp;
}

export async function getOrgName({ accessToken, networkId }) {
  const resp = await API.get(
    "eventHandler",
    `/api/v1/content-simulation/getNetworkOrgName?networkId=${networkId}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return resp;
}

export async function getOrgCategory({ accessToken, networkId }) {
  const resp = await API.get(
    "eventHandler",
    `/api/v1/content-simulation/getNetworkOrgCategory?networkId=${networkId}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return resp;
}
