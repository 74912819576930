// components/CustomizationDrawer.tsx
import React, { useEffect, useRef, useState } from 'react';
import { Drawer, Tooltip } from '@mui/material';

import "./CodeSetupDrawer.css";
import JsonIcon from '../../../icon/JsonIcon';
import { CopyBlock, dracula } from 'react-code-blocks';
import config from './uikit.config.json'
import CloseIcon from '../../../icon/CloseIcon';
import { useNavbar } from '../../../hooks/useNavbar';
import { useCustomization } from '../../../hooks/useCustomization';
import { Button, ColorPickerProps, GetProp, Tabs } from 'antd';
import TabPane from 'antd/es/tabs/TabPane';
import { CodeBlock } from '../InspectCodeDrawer/InspectCodeDrawer';
interface CodeDrawerProps {
    showDrawer: boolean;
    setShowDrawer?: (show: boolean) => void;
    // setPrimaryColor: (color: string) => void;
    // setTheme?: (theme: string) => void;
    // setPlatform?: (platform: string) => void;
    // setSecondaryColor?: (color: string) => void;
    // setTextCaptionColor?: (color: string) => void;
    // setBackgroundColor?: (color: string) => void;
    // getContainer?: any | null;
}



const CodeSetUpDrawer: React.FC<CodeDrawerProps> = ({ showDrawer }) => {
    const [uiKitConfig, setUIKitConfig] = useState<typeof config>({ ...config });
    const [jsonString, setJsonString] = useState<string>()
    const { applyTheme } = useCustomization();
    const { selectedTheme } = useNavbar()

    const languageMap = {
        iOS: "swift",
        Android: "kotlin",
        Web: "javascript",
        "React Native": "javascript",
    };
    

    const { updateCodeMenu, selectedCodeMenu } = useNavbar()

    useEffect(() => {

        const jsonString = JSON.stringify(uiKitConfig, null, 2);
        setJsonString(jsonString)


    }, [uiKitConfig])

    useEffect(() => {

        setUIKitConfig((prevConfig) => ({
            ...prevConfig,
            theme: {
                ...prevConfig.theme,
                light: {
                    ...prevConfig.theme.light,
                    primary_color: applyTheme.primary,
                    background_color: applyTheme.background,
                    base_color: applyTheme.base,
                    base_shade1_color: applyTheme.baseShade1,
                    base_shade4_color: applyTheme.border,


                },
                dark: {
                    ...prevConfig.theme.dark,
                    primary_color: applyTheme.primary,

                },
            },
        }));

    }, [applyTheme])

    useEffect(() => {
        if (selectedTheme === "dark") {
            setUIKitConfig((prevConfig) => ({
                ...prevConfig,
                preferred_theme: "dark",
            }));

        } else {
            setUIKitConfig((prevConfig) => ({
                ...prevConfig,
                preferred_theme: "light",
            }));

        }
    }, [selectedTheme])


    return (
        <Drawer
            open={showDrawer}
            anchor="right"
            PaperProps={{
                style: {
                    background: 'transparent',
                    width: '100%',
                    boxShadow: 'none',
                    zIndex: 1,
                    paddingLeft: 12,
                    overflow: 'hidden',
                    marginTop: 83
                    //style props for your drawer menu here
                },

            }}
            ModalProps={{
                slots: { backdrop: "div" },
                slotProps: {
                    root: { //override the fixed position + the size of backdrop
                        style: {
                            position: "absolute",
                            bottom: 100,
                            left: 'unset',
                            right: 'unset'

                        },
                    },
                },
            }}

        >
            <div className='code-drawer-modal'>
                <div className='code-drawer-container'>
                    <div className='code-drawer-title'>
                        <div className='code-drawer-title'>


                            <JsonIcon width={28} height={28} color='#424242' />
                            <p style={{ marginLeft: 8 }}>View Code</p>
                        </div>
                        <div onClick={() => updateCodeMenu("")}>
                            <CloseIcon width={24} height={24} />
                        </div>


                    </div>
                    <div className="code-snippet-tabs-section">
                        <Tabs
                    tabBarStyle={{ color: "#8c97a5" }}
                    className="custom-tab-bar"
                    animated={false}
                    tabBarGutter={0}
                    type="card"
                    defaultActiveKey={Object.keys(languageMap)[0]}
                >
                    {Object.entries(languageMap).map(([platform]) => (
                        <TabPane forceRender={true} tab={platform} key={platform}>
                            <div className="code-snippet">
                                <CodeBlock platform={platform} module={module} primaryColor={applyTheme.primary} apiKey={"xxxxxxxxxxxxxxx"} />
                            </div>
                        </TabPane>
                    ))}
                </Tabs>
                    </div>
                    <p className="drawer-subtitle">
                        If you have not integrated Social+ UIKit yet, please refer to the
                        documentation for your specific platform.
                    </p>
                    <ul>
                        <li>
                            <a
                                className="link-color"
                                href="https://docs.amity.co/amity-uikit/uikit-v4-beta/installation-guide/ios"
                                target="_blank"
                                rel="noreferrer"
                            >
                                iOS
                            </a>
                        </li>
                        <li>
                            <a
                                className="link-color"
                                href="https://docs.amity.co/amity-uikit/uikit-v4-beta/installation-guide/android"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Android
                            </a>
                        </li>
                        <li>
                            <a
                                className="link-color"
                                href="https://docs.amity.co/amity-uikit/web/web-uikit-installation-guide"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Web
                            </a>
                        </li>
                        <li>
                            <a
                                className="link-color"
                                href="https://docs.amity.co/amity-uikit/react-native-beta/setup-and-authentication"
                                target="_blank"
                                rel="noreferrer"
                            >
                                React Native
                            </a>
                        </li>
                    </ul>
                </div>



            </div>



            {/* Drawer content */}
        </Drawer>
    )
};

export default CodeSetUpDrawer;
