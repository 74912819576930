import React, { useEffect, useState } from 'react';
import './Dropdown.css'
import { ReactComponent as CheckmarkIcon } from './checkmark.svg'

export interface IDropDownOption {
    icon?: JSX.Element;  // JSX.Element for icon
    text: string;       // Text of the option
    value: string;
}

interface DropDownProps extends React.HTMLAttributes<HTMLDivElement> {
    options: IDropDownOption[];
    selectedValue?: string;                // The value of the currently selected option
    showDropDown: boolean;
    toggleDropDown: () => void;           // Function to toggle the dropdown
    optionSelection?: (value: string) => void; // Function to handle option selection
    isHideCheckMark?: boolean
} // Replace with your actual icon import

export const DropDown: React.FC<DropDownProps> = ({
    options,
    selectedValue,
    showDropDown,
    toggleDropDown,
    optionSelection,
    isHideCheckMark,
    ...props
}) => {
       
    // This function will be called when an option is clicked
    const onClickHandler = (value: string): void => {
        optionSelection && optionSelection(value);
        toggleDropDown(); // Optionally close the dropdown after a selection is made
    };

    return (
        <div {...props} className={showDropDown ? 'dropdown active' : 'dropdown'}>
            {options.map((option, index) => (
                <div
                    key={index}
                    className={`dropdown-item ${selectedValue === option.text ? 'selected' : ''}`}
                    onClick={() => onClickHandler(option.value)}
                >
                    <span className="dropdown-item-icon">{option.icon}</span>
                    <span className={`dropdown-item-text ${selectedValue === option.text ? 'bold' : ''}`}>
                        {option.text}
                    </span>
                    {(selectedValue === option.text) && (!isHideCheckMark) && (

                        <span className="dropdown-item-checkmark">
                            <CheckmarkIcon />
                        </span>
                    )}
                </div>
            ))}
        </div>
    );
};


