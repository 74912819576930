import React from "react";

function CodeIcon({ width = 24, height = 24, ...props }) {
    return (
        <svg width={width} height={height} {...props}>
            <path d="M14.5312 4.16504C15.1289 4.34082 15.4805 4.93848 15.3047 5.53613L10.8047 21.2861C10.6289 21.8838 10.0312 22.2354 9.43359 22.0596C8.83594 21.8838 8.48438 21.2861 8.66016 20.6885L13.1602 4.93848C13.3359 4.34082 13.9336 3.98926 14.5312 4.16504ZM17.3789 8.38379C17.8008 7.92676 18.5391 7.92676 18.9609 8.38379L22.8984 12.3213C23.3555 12.7432 23.3555 13.4814 22.8984 13.9033L18.9609 17.8408C18.5391 18.2979 17.8008 18.2979 17.3789 17.8408C16.9219 17.4189 16.9219 16.6807 17.3789 16.2588L20.5078 13.0947L17.3789 9.96582C16.9219 9.54395 16.9219 8.80566 17.3789 8.38379ZM6.58594 8.38379C7.04297 8.80566 7.04297 9.54395 6.58594 9.96582L3.45703 13.0947L6.58594 16.2588C7.04297 16.6807 7.04297 17.4189 6.58594 17.8408C6.16406 18.2979 5.42578 18.2979 5.00391 17.8408L1.06641 13.9033C0.609375 13.4814 0.609375 12.7432 1.06641 12.3213L5.00391 8.38379C5.42578 7.92676 6.16406 7.92676 6.58594 8.38379Z" fill="#424242" />
        </svg>

    );
}

export default CodeIcon;
