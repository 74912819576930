// components/CustomizationDrawer.tsx
import React, { useEffect, useRef, useState } from 'react';
import { FaChevronLeft } from 'react-icons/fa6';

import { Drawer, Tooltip } from '@mui/material';

import "./LayerDrawer.css";
import LayerItem from '../../LayerItem/LayerItem';
import ApplicationIcon from '../../../icon/ApplicationIcon';
import { useLayerItems } from '../../../hooks/useLayerItems';
import { NavigationItem } from '../../../redux/slices/LayerDrawerSlice';
import { useNavbar } from '../../../hooks/useNavbar';

interface LayerDrawerProps {
    showLayer: boolean;
    setShowLayer: (show: boolean) => void;
    // setPrimaryColor: (color: string) => void;
    // setTheme?: (theme: string) => void;
    // setPlatform?: (platform: string) => void;
    // setSecondaryColor?: (color: string) => void;
    // setTextCaptionColor?: (color: string) => void;
    // setBackgroundColor?: (color: string) => void;
    // getContainer?: any | null;
}
interface ILayerItemprops {
    name: string;
    level: 'page' | 'component' | 'element'
}
const socialLayers: ILayerItemprops[] = [
    { name: 'Newsfeed', level: 'page' },
    { name: 'Explore', level: 'page' },
    { name: 'My community', level: 'page' },
    { name: 'Community profile', level: 'page' },
    { name: 'User Profile', level: 'page' }]

const chatLayers: ILayerItemprops[] = [
    { name: 'Chat list', level: 'page' },
    { name: 'Chat room', level: 'page' },
    { name: 'Chat profile community', level: 'page' },]


const LayerDrawer: React.FC<LayerDrawerProps> = ({ showLayer, setShowLayer }) => {

    const global = {
        uiReference: "*/*/*",
        parentUIReference: "none",
        name: "App"
    }
    const [mouseX, setMouseX] = useState(null);
    const [mouseY, setMouseY] = useState(null);

    const { socialLayerItems, chatLayerItems, checkIsSelect, updateCurrentSelect } = useLayerItems();
    const { selectedContent } = useNavbar();

    useEffect(() => {
        handleSelectAppLayer()
    }, [selectedContent])



    useEffect(() => {
        setMouseY(mouseY)
    }, [mouseY])

    const handleSelectAppLayer = () => {
        updateCurrentSelect(global)
    }
    const renderGlobalTheme = () => {
        return (
            <div onClick={handleSelectAppLayer} className={`global-theme-row ${checkIsSelect(global.uiReference) ? 'active-state' : 'inactive-state'}`}>
                <ApplicationIcon color={`${checkIsSelect(global.uiReference) ? '#3B41EC' : '#424242'}`} />
                <p className={`global-theme-text ${checkIsSelect(global.uiReference) ? 'active-state-text' : 'inactive-state'}`}>App</p>

            </div>
        )

    }

    const renderSocialSection = () => {
        return (
            <div className='section-container'>
                <p className='section-text'>Social</p>
                {socialLayerItems.map((item: NavigationItem) => {
                    if (item.parentUIReference === '*/*/*') {
                        return (
                            <LayerItem item={item} module='social' />
                        )
                    }

                })}

            </div>
        )

    }

    const renderChatSection = () => {
        return (
            <div className='section-container'>
                <p className='section-text'>Chat</p>
                {chatLayerItems.map((item: NavigationItem) => {
                    if (item.parentUIReference === '*/*/*') {
                        return (
                            <LayerItem item={item} module='chat' />
                        )
                    }

                })}

            </div>
        )

    }
    return (
        <Drawer
            key={1}
            PaperProps={{
                style: {
                    background: 'transparent',
                    width: 320,
                    marginTop: 83,
                    boxShadow: 'none',
                    zIndex: 1,
                    paddingRight: 15,
                    overflow: 'hidden'
                    //style props for your drawer menu here
                },
            }}

            ModalProps={{
                keepMounted: true,
                hideBackdrop: true,
                style: {
                    position: "absolute",
                    bottom: 100,
                    left: 'unset',
                    right: 'unset'
                }
            }}
            open={showLayer}
            anchor="left"
            disableEnforceFocus


        >

            <div onClick={() => setShowLayer(false)} className='close-layer-container'>

                <>

                    <Tooltip title='Close Sidebar' placement='right-end' arrow followCursor>
                        <div >
                            <div className="right-border-container" />
                    
                            <FaChevronLeft  className='sticky-button-layer-drawer' />
                  
                          
                        </div>

                    </Tooltip>

                </>

            </div>

            <div className='layer-drawer-container'>
                {renderGlobalTheme()}
                {renderSocialSection()}
                <div className='section-divider' />
                {/* {renderChatSection()} */}
            </div>

            {/* Drawer content */}
        </Drawer>
    )
};

export default LayerDrawer;
