import React from "react";

function CloseIcon({ width = 21, height = 20, ...props }) {
    return (
        <svg width={width} height={height} viewBox="0 0 21 20" style={{ cursor: 'pointer' }} fill="none" {...props}>
            <path d="M12.1631 9.92871L16.3818 14.1475C16.6162 14.3818 16.6162 14.8115 16.3818 15.0459L15.4053 16.0225C15.1709 16.2568 14.7412 16.2568 14.5068 16.0225L10.3271 11.8037L6.1084 16.0225C5.87402 16.2568 5.44434 16.2568 5.20996 16.0225L4.2334 15.0459C3.99902 14.8115 3.99902 14.3818 4.2334 14.1475L8.45215 9.92871L4.2334 5.74902C3.99902 5.51465 3.99902 5.08496 4.2334 4.85059L5.20996 3.87402C5.44434 3.63965 5.87402 3.63965 6.1084 3.87402L10.3271 8.09277L14.5068 3.87402C14.7412 3.63965 15.1709 3.63965 15.4053 3.87402L16.3818 4.85059C16.6162 5.08496 16.6162 5.51465 16.3818 5.74902L12.1631 9.92871Z" fill="#757575" />
        </svg>


    );
}

export default CloseIcon;
