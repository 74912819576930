import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ILayerItemprops } from "../../components/LayerItem/LayerItem";

interface UIKitModuleState {
  selectedUIKit: "social" | "chat" | "story";
  uikitOptions: { text: string; value: string }[];
}
export type NavigationItem = {
  uiReference: string;
  parentUIReference: string;
  name: string;
};
const initialState: UIKitModuleState = {
  uikitOptions: [
    {
      text: "Social",
      value: "social",
    },
    // {
    //   text: "Chat",
    //   value: "chat",
    // },
    // {
    //   text: "Story",
    //   value: "story",
    // },
    {
      text: "Live-Chat",
      value: "live-chat",
    },
  ],
  selectedUIKit: "social",
};

const uiKitModuleSlice = createSlice({
  name: "uikitModule",
  initialState,
  reducers: {
    updateSelectedUIKit: (
      state,
      action: PayloadAction<"social" | "chat" | "story">
    ) => {
      const item = action.payload;
      state.selectedUIKit = item;
    },
  },
});

export default uiKitModuleSlice;
