
import React from "react";

function RocketIcon({ width = 24, height = 24, color = '#ffffff', ...props }) {
    return (
        <svg width={width} height={height} viewBox="0 0 25 25" fill="none" {...props}>
            <path d="M21.2539 4.63086C21.5 5.75586 21.5 6.63477 21.4648 7.51367C21.4648 11.1348 19.5312 13.3145 16.9648 14.9316V18.623C16.9648 19.1855 16.5781 19.8535 16.0508 20.1348L12.5703 21.8574C12.4648 21.8926 12.3242 21.9277 12.2188 21.9277C11.7266 21.9277 11.375 21.5762 11.375 21.084V17.4277C11.375 15.5996 9.86328 14.0527 8 14.0527H4.34375C3.85156 14.0527 3.5 13.7012 3.5 13.209C3.5 13.1035 3.53516 12.9629 3.57031 12.8574L5.32812 9.37695C5.57422 8.84961 6.24219 8.42773 6.80469 8.42773H10.4961C12.1133 5.89648 14.293 3.92773 17.9141 3.92773C18.793 3.92773 19.6719 3.92773 20.7969 4.17383C21.0078 4.24414 21.1836 4.41992 21.2539 4.63086ZM17 9.83398C17.7734 9.83398 18.4062 9.23633 18.4062 8.42773C18.4062 7.6543 17.7734 7.02148 17 7.02148C16.1914 7.02148 15.5938 7.6543 15.5938 8.42773C15.5938 9.23633 16.1914 9.83398 17 9.83398ZM4.73047 16.3379C6.13672 14.8965 8.03516 14.8262 9.33594 16.0918C10.6016 17.3926 10.5312 19.291 9.08984 20.6973C8.21094 21.6113 5.92578 22.0332 3.5 21.9277C3.39453 19.502 3.81641 17.2168 4.73047 16.3379ZM7.61328 19.291C8.07031 18.7988 8.10547 18.166 7.68359 17.7441C7.26172 17.3223 6.62891 17.3574 6.13672 17.8145C5.85547 18.1309 5.71484 18.8691 5.75 19.6777C6.55859 19.7129 7.29688 19.5723 7.61328 19.291Z" fill={color} />
        </svg>

    );
}

export default RocketIcon;
