import { PayloadAction, createSlice } from '@reduxjs/toolkit';



interface CustomizationState {
  globalTheme: IGlobaltheme;
  applyTheme: Record<string, any>
}

interface IGlobaltheme {
  primary: string;
  background: string;
  border: string;
  base: string;
  baseShade1: string;
}
const initialState: CustomizationState = {
  globalTheme: {
    primary: '#1054de',
    background: '#ffffff',
    border: '#ebecef',
    base: '#292b32',
    baseShade1: '#a5a9b5'
  },
  applyTheme: {}

};

const customizationSlice = createSlice({
  name: 'customization',
  initialState,
  reducers: {


    updateCustomizeTheme: (
      state,
      action: PayloadAction<{ themeKey: string, valueKey: string, data: string }>
    ) => {
      const { themeKey, valueKey, data } = action.payload;

      state[themeKey][valueKey] = data;
    },
    resetGlobalTheme: (
      state,
    ) => {
      state.globalTheme = initialState.globalTheme
    },

    saveTheme: (
      state,
      action: PayloadAction<Record<string, any>>
    ) => {
      const data = action.payload;
      state.applyTheme = data;
    },


  },
});

export default customizationSlice;
